import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Alert } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import Select from '../../../components/basic/SelectField';
import PricingFeeRates from './PricingFeeRates/PricingFeeRates';
import Tooltip from '../../../components/quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const formatColumnOption = c => ({ label: c.replace(':', ' => '), value: c });

export default ({ fees, feeRateLookupColumns, feeRateStateColumns, onSaveFee, onRemoveFee, axiosConfig }) => {
  const [selectedFee, setSelectedFee] = useState();
  const [feeRates, setFeeRates] = useState();
  const [grinders, setGrinders] = useState();
  const [packerPlants, setPackerPlants] = useState();
  const [endUsers, setEndUsers] = useState();
  const [uoms, setUoms] = useState();
  const [coldStores, setColdStores] = useState();
  const [countries, setCountries] = useState();
  const [productTypes, setProductTypes] = useState();
  const [currencies, setCurrencies] = useState();
  const [incoterms, setIncoterms] = useState();
  const [productCategories, setProductCategories] = useState();

  const [showFormModal, setShowFormModal] = useState(false);

  useEffect(() => {
    if (selectedFee) {
      loadFeeRates();
    }
  }, [selectedFee]);

  useEffect(() => {
    if (!axiosConfig) {
      return;
    }
    loadGrinders();
    loadPackerPlants();
    loadEndUsers();
    loadUnitOfMeasures();
    loadColdStores();
    loadFormFields();
    loadCountries();
  }, [axiosConfig]);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      uid: Yup.string().nullable().required('Required'),
      label: Yup.string().nullable().required('Required'),
      abbreviation: Yup.string().nullable().required('Required'),
      category: Yup.string().nullable().required('Required'),
      description: Yup.string().nullable().required('Required'),
      lookup_columns: Yup.array().nullable().required('Required'),
      state_columns: Yup.array().nullable().required('Required'),
    }),
    onSubmit: data => {
      onSaveFee(data);
      closeFormModal();
    },
  });

  const openFormModal = (fee = {}) => {
    resetForm();
    setValues(fee);
    setShowFormModal(true);
  };

  const closeFormModal = () => setShowFormModal(false);

  const removeFee = () => {
    if (confirm('Are you sure you want to delete this item?') == true) {
      onRemoveFee(values);
      closeFormModal();
    }
  };

  /* Grinders */
  const loadGrinders = () => {
    axios.get(`${Constants.URL}grinders`, axiosConfig).then(response => {
      setGrinders(response.data.map(g => ({ label: `(${g.uid}) ${g.name}`, value: g.uid })));
    });
  };

  /* Packer plants */
  const loadPackerPlants = () => {
    axios.get(`${Constants.URL}packer_plants`, axiosConfig).then(response => {
      setPackerPlants(response.data.map(pp => ({ label: `(${pp.id}) ${pp.name}`, value: pp.id })));
    });
  };

  /* Customers */
  const loadEndUsers = () => {
    axios.get(`${Constants.URL}customers`, axiosConfig).then(response => {
      setEndUsers(response.data.map(eu => ({ label: `(${eu.id}) ${eu.common_name}`, value: eu.id })));
    });
  };

  /* Unit of measures */
  const loadUnitOfMeasures = () => {
    axios.get(`${Constants.URL}units`, axiosConfig).then(response => {
      setUoms(response.data.map(item => ({ label: item.name, value: item.id })));
    });
  };

  /* Cold stores */
  const loadColdStores = () => {
    axios.get(`${Constants.URL}cold_stores`, axiosConfig).then(response => {
      setColdStores(response.data.map(item => ({ label: item.company_name, value: item.id })));
    });
  };

  /* Countries */
  const loadCountries = () => {
    axios.get(`${Constants.URL}countries`, axiosConfig).then(response => {
      setCountries(response.data.map(item => ({ label: item.name, value: item.name })));
    });
  };

  /* Form Fields */
  const loadFormFields = () => {
    axios.get(`${Constants.URL}form_fields`, axiosConfig).then(response => {
      const formFields = response.data.reduce((o, c) => {
        if (!Array.isArray(o[c.type])) {
          o[c.type] = [];
        }
        o[c.type].push(c);
        return o;
      }, {});
      setProductTypes(formFields.product_types.map(item => ({ label: item.value, value: item.value })));
      setCurrencies(formFields.currency.map(item => ({ label: item.value, value: item.value })));
      setIncoterms(formFields.incoterms.map(item => ({ label: item.value, value: item.value })));
      setProductCategories(formFields.input_product_category.map(item => ({ label: item.value, value: item.value })));
    });
  };

  /* Fee rates */
  const loadFeeRates = () => {
    axios.get(`${Constants.URL}pricing/fee-rates?fee_uid=${selectedFee.uid}`, axiosConfig).then(response => {
      setFeeRates(response.data);
    });
  };

  const onSaveFeeRate = feeRate => {
    (!feeRate.created
      ? axios.post(`${Constants.URL}pricing/fee-rates`, { ...feeRate, fee_uid: selectedFee.uid }, axiosConfig)
      : axios.patch(`${Constants.URL}pricing/fee-rates/${feeRate.id}`, feeRate, axiosConfig)
    ).then(loadFeeRates);
  };

  const onRemoveFeeRate = feeRate => {
    axios.delete(`${Constants.URL}pricing/fee-rates/${feeRate.id}`, axiosConfig).then(loadFeeRates);
  };

  if (selectedFee) {
    return (
      <PricingFeeRates
        selectedFee={selectedFee}
        setSelectedFee={setSelectedFee}
        setFeeRates={setFeeRates}
        feeRates={feeRates}
        grinders={grinders}
        packerPlants={packerPlants}
        endUsers={endUsers}
        uoms={uoms}
        productTypes={productTypes}
        currencies={currencies}
        countries={countries}
        incoterms={incoterms}
        productCategories={productCategories}
        coldStores={coldStores}
        onSaveFeeRate={onSaveFeeRate}
        onRemoveFeeRate={onRemoveFeeRate}
      />
    );
  }
  return (
    <Box>
      <VStack marginBottom="80px" alignItems="end">
        <Button colorScheme="actionPrimary" onClick={() => openFormModal()}>
          New
        </Button>
      </VStack>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Label</Th>
              <Th>Abbreviation</Th>
              <Th>Category</Th>
              <Th>Description</Th>
              <Th>Lookup Columns</Th>
              <Th>State Columns</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {fees &&
              fees.map(item => (
                <Tr key={item.uid}>
                  <Td
                    _hover={{ bg: 'blue.500', color: ' white' }}
                    cursor="pointer"
                    onClick={() => setSelectedFee(item)}
                  >
                    {item.label}
                  </Td>
                  <Td>{item.abbreviation}</Td>
                  <Td>{item.category}</Td>
                  <Td>{item.description}</Td>
                  <Td>
                    {(item.lookup_columns || []).map(formatColumnOption).map(c => (
                      <div key={c.value}>
                        <Tag marginBottom="6px">{c.label}</Tag>
                        <br />
                      </div>
                    ))}
                  </Td>
                  <Td>
                    {(item.state_columns || []).map(formatColumnOption).map(c => (
                      <div key={c.value}>
                        <Tag marginBottom="6px">{c.label}</Tag>
                        <br />
                      </div>
                    ))}
                  </Td>
                  <Td>
                    <Button onClick={() => openFormModal(item)}>Edit</Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal size="6xl" isOpen={showFormModal} onClose={closeFormModal}>
        <ModalOverlay />
        <ModalContent minHeight="200px">
          <form onSubmit={handleSubmit}>
            <ModalHeader>Fee</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="10">
                <FormControl id="label" isInvalid={errors.label && touched.label}>
                  <FormLabel>
                    Label&nbsp;
                    <Tooltip
                      content="Enter a unique name to identify this fee within the Pricing Engine"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input name="label" type="text" onChange={handleChange} onBlur={handleBlur} value={values.label} />
                  {errors.label && touched.label && (
                    <Alert style={{ marginTop: '5px' }} message={errors.label} type="error" />
                  )}
                </FormControl>
                <FormControl id="abbreviation" isInvalid={errors.abbreviation && touched.abbreviation}>
                  <FormLabel>
                    Abbreviation&nbsp;
                    <Tooltip
                      content="Provide the abbreviation or short form that represents this fee"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="abbreviation"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.abbreviation}
                  />
                  {errors.abbreviation && touched.abbreviation && (
                    <Alert style={{ marginTop: '5px' }} message={errors.abbreviation} type="error" />
                  )}
                </FormControl>
                <FormControl id="category" isInvalid={errors.category && touched.category}>
                  <FormLabel>
                    Category&nbsp;
                    <Tooltip content="Specify the category to which this fee belongs" placement="right">
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="category"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                  />
                  {errors.category && touched.category && (
                    <Alert style={{ marginTop: '5px' }} message={errors.category} type="error" />
                  )}
                </FormControl>
                <FormControl id="description" isInvalid={errors.description && touched.description}>
                  <FormLabel>
                    Description&nbsp;
                    <Tooltip
                      content="Provide detailed information about this fee to better understand its purpose"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Input
                    name="description"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <Alert style={{ marginTop: '5px' }} message={errors.description} type="error" />
                  )}
                </FormControl>
                <FormControl id="uid" isInvalid={errors.uid && touched.uid}>
                  <FormLabel>Name *</FormLabel>
                  <Input
                    name="uid"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.uid}
                    readOnly={values.created}
                  />
                  {errors.uid && touched.uid && (
                    <Alert style={{ marginTop: '5px' }} message={errors.uid} type="error" />
                  )}
                </FormControl>
                <FormControl id="lookup_columns" isInvalid={errors.lookup_columns && touched.lookup_columns}>
                  <FormLabel>
                    Lookup Columns&nbsp;
                    <Tooltip
                      content="Select the fields that should be used to look up the relevant data for this fee."
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'lookup_columns',
                      value: (values.lookup_columns || []).map(formatColumnOption),
                      onChange: lookup_columns => {
                        setFieldValue(
                          'lookup_columns',
                          lookup_columns.map(c => c.value)
                        );
                      },
                      options: (feeRateLookupColumns || []).map(formatColumnOption),
                    }}
                  />
                  {errors.lookup_columns && touched.lookup_columns && (
                    <Alert style={{ marginTop: '5px' }} message={errors.lookup_columns} type="error" />
                  )}
                </FormControl>
                <FormControl id="state_columns" isInvalid={errors.state_columns && touched.state_columns}>
                  <FormLabel>
                    State Columns&nbsp;
                    <Tooltip
                      content="Select the state in which this fee needs to be calculated, such as Rate, Free Days, or Roll Period Days"
                      placement="right"
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        icon={<IoInformationCircleOutline size="14px" />}
                        variant="unstyled"
                      />
                    </Tooltip>
                    *
                  </FormLabel>
                  <Select
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: false,
                      removeSelected: true,
                      isMulti: true,
                      name: 'state_columns',
                      value: (values.state_columns || []).map(formatColumnOption),
                      onChange: state_columns => {
                        setFieldValue(
                          'state_columns',
                          state_columns.map(c => c.value)
                        );
                      },
                      options: (feeRateStateColumns || []).map(formatColumnOption),
                    }}
                  />
                  {errors.state_columns && touched.state_columns && (
                    <Alert style={{ marginTop: '5px' }} message={errors.state_columns} type="error" />
                  )}
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              {values.created && (
                <Button type="button" colorScheme="error" onClick={removeFee} marginRight="16px">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" isDisabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};
