import React from 'react';
import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Tooltip = ({ children, content, placement }) => {
  return (
    <Popover placement={placement || 'bottom'} trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        borderRadius="4px"
        backgroundColor="#4D4D4D"
        color="#fff"
        padding="4px 8px"
        width="fit-content"
        _focus={{
          outline: 'none',
        }}
      >
        <PopoverArrow borderColor="#4D4D4D" backgroundColor="#4D4D4D" />
        <PopoverBody padding={0}>
          <Text as="p" fontSize="12px" fontWeight="normal">
            {content}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

export default Tooltip;
