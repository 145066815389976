import cloneDeep from 'lodash/cloneDeep';
import {
  ADD_LINE,
  COLD_STORE_SEND_EMAIL_ERROR,
  CONF_DOC_EMAIL_LOADING,
  CONF_DOC_EMAIL_SENT,
  DELETE_ORDER,
  EMAIL_CONF_DOC_RESET,
  FINISH_ORDER_CREATION,
  FINISH_ORDER_UPDATE,
  LOAD_EDIT_ORDER,
  LOAD_FAST_ORDERS,
  LOAD_NETSUITE_ORDER,
  LOAD_ORDER_HISTORY_BY_USER,
  LOAD_ORDER_HISTORY,
  LOAD_ORDER_PENDING,
  LOAD_ORDER,
  LOAD_PENDING_ORDERS,
  ORDER_ERROR,
  PREVIEW_PDF,
  RELEASE_PDF_EMAIL_SENT,
  REPLACE_ORDER,
  REPLACE_ORDERS,
  REQUEST_NETSUITE_ORDER,
  RESET_COLD_STORE_MODAL,
  RESET_MODAL_ORDER,
  RESET_ORDER,
  RESET_PURCHASE_ORDERS,
  START_ORDER_CREATION,
  START_ORDER_UPDATE,
  START_SEARCH,
  EXPORT_LOAD_ORDER_HISTORY,
} from '../actions/actions_orders';
import { replaceObjectInArray, replaceObjectsInArray } from '../helpers';

const initialState = {
  orders: [],
  order_history: [],
  order_export_history: [],
  orderHistoryPagination: {},
  pending_orders: [],
  user_order_history: [],
  historyLoaded: false,
  pendingLoaded: false,
  latestOrders: [],
  confDocEmail: {
    confDocEmailSent: false,
    loading: false,
  },
  previewOrders: '',
  isLoadingNetsuiteOrder: false,
  isSearching: false,
  hasExecutedSearchs: false,
  isSavingOrder: false,
  isCreatingOrder: false,
  hasCreatedOrder: false,
  hasError: false,
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case START_SEARCH: {
      return {
        ...state,
        isSearching: true,
      };
    }

    case LOAD_PENDING_ORDERS: {
      const pendingOrders = JSON.parse(JSON.stringify(state.pending_orders));
      action.payload.forEach(function (pending) {
        pendingOrders.unshift(pending);
      });
      return {
        ...state,
        pendingLoaded: true,
        pending_orders: pendingOrders,
        latestOrders: action.payload,
      };
    }
    case LOAD_ORDER: {
      let newPending = JSON.parse(JSON.stringify(state.pending_orders));
      const userOrders = JSON.parse(JSON.stringify(state.user_order_history));
      const newOrderHistory = replaceObjectInArray(state.order_history, action.payload);

      if (action.payload.status === 'pending') {
        newPending.unshift(action.payload);
      } else if (action.payload.status === 'ordered') {
        newPending = [];
        state.pending_orders.forEach(function (order) {
          if (order.id !== action.payload.id) {
            newPending.unshift(order);
          }
        });
        userOrders.unshift(action.payload);
      }
      return {
        ...state,
        fastLoaded: action.payload.fastLoaded,
        latestOrders: [action.payload],
        orders: action.payload,
        pending_orders: newPending,
        order_history: newOrderHistory,
        user_order_history: userOrders,
      };
    }
    case LOAD_FAST_ORDERS: {
      const ordersCopy = cloneDeep(state.orders);
      let fastOrders = [];
      // workaround for an issue - r.unshift is not a function
      // untill we find where ordersCopy are returned as Object
      if (ordersCopy instanceof Array) {
        fastOrders = ordersCopy;
      } else {
        fastOrders.push(ordersCopy);
      }

      action.payload.forEach(function (order) {
        fastOrders.unshift(order);
      });
      const userOrders = cloneDeep(state.user_order_history);
      action.payload.forEach(function (order) {
        userOrders.unshift(order);
      });

      return {
        ...state,
        fastLoaded: true,
        orders: fastOrders,
        user_order_history: userOrders,
        latestOrders: action.payload,
        isCreatingOrder: false,
      };
    }
    case REPLACE_ORDER: {
      const newPending = replaceObjectInArray(state.pending_orders, action.payload);
      const newUserOrderHistory = replaceObjectInArray(state.user_order_history, action.payload);
      const newOrderHistory = replaceObjectInArray(state.order_history, action.payload);

      if (state.netsuite_purchase_order) {
        action.payload.netsuite_purchase_order = { ...state.netsuite_purchase_order };
      }

      return {
        ...state,
        orderUpdated: action.payload.orderUpdated,
        pending_orders: newPending,
        user_order_history: newUserOrderHistory,
        order_history: newOrderHistory,
        ...(state.current_modal_detail && { current_modal_detail: action.payload }),
        ...(state.netsuite_purchase_order && { netsuite_purchase_order: action.payload }),
      };
    }
    case REPLACE_ORDERS:
      return {
        ...state,
        pending_orders: replaceObjectsInArray(state.pending_orders, action.payload),
        user_order_history: replaceObjectInArray(state.user_order_history, action.payload),
        order_history: replaceObjectInArray(state.order_history, action.payload),
      };

    case DELETE_ORDER: {
      const newPending = [];
      state.pending_orders.forEach(function (pending) {
        if (pending.id !== action.payload.id) {
          newPending.push(pending);
        }
      });
      const newUserOrderHistory = [];
      state.user_order_history.forEach(function (user_order) {
        if (user_order.id !== action.payload.id) {
          newUserOrderHistory.push(user_order);
        } else {
          newUserOrderHistory.push(action.payload);
        }
      });
      const newOrderHistory = [];
      state.order_history.forEach(function (order) {
        if (order.id !== action.payload.id) {
          newOrderHistory.push(order);
        } else {
          newOrderHistory.push(action.payload);
        }
      });
      return {
        ...state,
        pending_orders: newPending,
        user_order_history: newUserOrderHistory,
        order_history: newOrderHistory,
      };
    }
    case LOAD_ORDER_PENDING:
      return {
        ...state,
        poFormPendingLoaded: true,
        pending_orders: action.payload,
      };
    case LOAD_ORDER_HISTORY: {
      const { items, ...rest } = action.payload;
      return {
        ...state,
        order_history: items || [],
        orderHistoryPagination: rest,
        isSearching: false,
        hasExecutedSearchs: true,
      };
    }
    case EXPORT_LOAD_ORDER_HISTORY: {
      const { items } = action.payload;
      return {
        ...state,
        export_order_history: items || [],
      };
    }
    case REQUEST_NETSUITE_ORDER:
      return {
        ...state,
        isLoadingNetsuiteOrder: true,
      };
    case LOAD_NETSUITE_ORDER: {
      const item = Object.assign({}, action.payload);
      item.transport_cost_per_unit = item.transport_cost_per_unit
        ? item.transport_cost_per_unit.toFixed(4)
        : item.transport_cost_per_unit;
      return {
        ...state,
        netsuite_purchase_order: item,
        isLoadingNetsuiteOrder: false,
      };
    }
    case RESET_MODAL_ORDER:
      return {
        ...state,
        current_modal_detail: false,
        netsuite_purchase_order: false,
      };
    case LOAD_ORDER_HISTORY_BY_USER:
      return {
        ...state,
        historyLoaded: true,
        user_order_history: action.payload,
      };
    case RESET_ORDER:
      return {
        ...state,
        orders: undefined,
        order_history: undefined,
      };

    case RESET_PURCHASE_ORDERS:
      return {
        ...state,
        orders: [],
        order_history: [],
        isSearching: false,
        hasExecutedSearchs: false,
      };
    case LOAD_EDIT_ORDER:
      return {
        ...state,
        current_modal_detail: action.payload,
      };

    case CONF_DOC_EMAIL_SENT:
      return {
        ...state,
        confDocEmail: {
          loading: false,
          confDocEmailSent: true,
        },
      };
    case EMAIL_CONF_DOC_RESET:
      return {
        ...state,
        confDocEmail: {
          loading: false,
          confDocEmailSent: false,
        },
      };

    case CONF_DOC_EMAIL_LOADING:
      return {
        ...state,
        confDocEmail: {
          loading: true,
          confDocEmailSent: false,
        },
      };
    // Can be deleted, not used
    case ADD_LINE:
      return {
        ...state,
        addedLineId: action.payload,
      };

    case START_ORDER_CREATION:
      return {
        ...state,
        hasError: false,
        isCreatingOrder: true,
        hasCreatedOrder: false,
      };

    case FINISH_ORDER_CREATION:
      return {
        ...state,
        isCreatingOrder: false,
        hasCreatedOrder: true,
      };

    case START_ORDER_UPDATE:
      return {
        ...state,
        isSavingOrder: true,
        hasError: false,
      };

    case FINISH_ORDER_UPDATE:
      return {
        ...state,
        isSavingOrder: false,
      };

    case ORDER_ERROR:
      return {
        ...state,
        hasError: true,
        isSavingOrder: false,
        isCreatingOrder: false,
      };

    default:
      return {
        ...state,
      };
  }
};

// Selectors
export const selectOrderSubmissionData = state => {
  return {
    isCreatingOrder: state.orders.isCreatingOrder,
    isSavingOrder: state.orders.isSavingOrder,
    hasCreatedOrder: state.orders.hasCreatedOrder,
    hasError: state.orders.hasError,
  };
};

export const selectNetsuitePurchaseOrder = state => {
  return state.orders.netsuite_purchase_order;
};

export const selectOrderHistorySearchResult = state => {
  // eslint-disable-next-line camelcase
  const { order_history, orderHistoryPagination, isSearching, hasExecutedSearchs, order_export_history } = state.orders;
  return {
    results: order_history,
    results_export: order_export_history,
    pagination: orderHistoryPagination,
    isSearching,
    hasExecutedSearchs,
  };
};

export default orders;
