import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonGroup, Flex, IconButton, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactComponent as TrashOutlineIconSVG } from '../../../assets/trash-outline-icon.svg';
import Constants from '../../../Constants';
import { getIngredientOptions } from '../../../utils';
import { sortInAlphabeticalOrder } from '../../../functions';
import SelectField from '../../basic/SelectField';

const LoadNotesTableTd = ({ children, ...rest }) => {
  return (
    <Td paddingRight="16px" paddingLeft={0} paddingTop="8px" {...rest}>
      {children}
    </Td>
  );
};

LoadNotesTableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultLoadNoteData = {
  category: null,
  reason: null,
  description: '',
  input_product_id: '',
  weight: 0,
};

const LoadNotes = ({ inputProducts, packerPlantOriginCountry, isInEditMode, loadNotes, onChange, onDelete }) => {
  const [state, setState] = useState({
    isExpanded: false,
    showProductCategoryCols: false,
  });

  const inputProductsOptions = getIngredientOptions(inputProducts, [
    {
      filterKey: 'COUNTRY',
      filterArgs: [packerPlantOriginCountry],
    },
  ]).sort((a, b) => {
    const packerA = a.label.toLowerCase();

    const packerB = b.label.toLowerCase();
    return sortInAlphabeticalOrder(packerA, packerB);
  });

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      isRequired: true,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      isRequired: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  const productCategoryCols = [
    {
      title: 'Product',
      dataIndex: 'input_product_id',
      isRequired: true,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      isRequired: true,
    },
  ];

  const handleAdd = () => {
    onChange([...loadNotes, { ...defaultLoadNoteData }]);
  };

  const handleDelete = index => {
    onDelete(index);
  };

  const handleChange = (indexOfLoadNote, key, value) => {
    const loadNotesCopy = [...loadNotes];
    if (key === 'category') {
      if (loadNotesCopy[indexOfLoadNote][key] !== value) {
        Object.keys(defaultLoadNoteData)
          .filter(defaultLoadNoteDataKey => defaultLoadNoteDataKey !== 'category')
          .map(defaultLoadNoteDataKey => {
            loadNotesCopy[indexOfLoadNote][defaultLoadNoteDataKey] = defaultLoadNoteData[defaultLoadNoteDataKey];
            return null;
          });
      }
    }
    loadNotesCopy[indexOfLoadNote][key] = value;

    onChange(loadNotesCopy);
  };

  useEffect(() => {
    const show = loadNotes.some(loadNote => loadNote.category === 'product');
    setState(prevState => ({
      ...prevState,
      showProductCategoryCols: show,
    }));
  }, [loadNotes]);

  return (
    <Table variant="unstyled" >
      <Thead>
        <Tr>
          <Th width="10px" padding="0" paddingRight="26px" />
          {columns.map(column => (
            <Th
              key={column.dataIndex}
              paddingLeft={0}
              paddingBottom={0}
              paddingTop={0}
              textTransform="capitalize"
              fontSize="14px"
              fontWeight="500"
              fontFamily="var(--chakra-fonts-body)"
            >
              {column.title} {column.isRequired && isInEditMode ? ' *' : ''}
            </Th>
          ))}
          {state.showProductCategoryCols &&
            productCategoryCols.map(column => (
              <Th
                key={column.dataIndex}
                paddingLeft={0}
                paddingBottom={0}
                paddingTop={0}
                textTransform="capitalize"
                fontSize="14px"
                fontWeight="500"
                fontFamily="var(--chakra-fonts-body)"
              >
                {column.title} {column.isRequired && isInEditMode ? ' *' : ''}
              </Th>
            ))}
          <Th />
        </Tr>
      </Thead>
      <Tbody backgroundColor="white">
        {loadNotes.map((loadNote, index) => (
          <Tr key={loadNote.id || index}>
            <LoadNotesTableTd paddingLeft="1em">
              <Text color="accent.one.default" fontWeight="700">
                {index + 1}
              </Text>
            </LoadNotesTableTd>
            <LoadNotesTableTd paddingLeft={0}>
              <Box minWidth="204px">
                <SelectField
                  {...{
                    isDisabled: !isInEditMode,
                    isSearchable: true,
                    closeMenuOnSelect: false,
                    name: 'select-category',
                    defaultValue: loadNote.category,
                    onChange: e => handleChange(index, 'category', e.value),
                    options: Constants.LOAD_NOTES_CATEGORIES,
                  }}
                />
              </Box>
            </LoadNotesTableTd>
            <LoadNotesTableTd paddingLeft={0}>
              <Box minWidth="204px">
                <SelectField
                  {...{
                    isDisabled: !isInEditMode,
                    isSearchable: true,
                    closeMenuOnSelect: false,
                    name: 'select-standing',
                    defaultValue: loadNote.reason,
                    onChange: e => handleChange(index, 'reason', e.value),
                    options: Constants.LOAD_NOTES_REASONS.filter(reasons =>
                      reasons.categories.includes(loadNote.category)
                    ),
                  }}
                />
              </Box>
            </LoadNotesTableTd>
            <LoadNotesTableTd paddingLeft={0}>
              <Input
                className="form-control"
                value={loadNote.description || ''}
                isDisabled={!isInEditMode}
                minWidth="210px"
                placeholder="Add description"
                onChange={e => handleChange(index, 'description', e.target.value)}
              />
            </LoadNotesTableTd>
            {loadNote.category === 'product' ? (
              <>
                <LoadNotesTableTd paddingLeft={0}>
                  <Box minWidth="204px">
                    <SelectField
                      {...{
                        isDisabled: !isInEditMode,
                        isSearchable: true,
                        closeMenuOnSelect: false,
                        name: 'select-product',
                        defaultValue: loadNote.input_product_id,
                        onChange: e => handleChange(index, 'input_product_id', e.value),
                        options: inputProductsOptions,
                      }}
                    />
                  </Box>
                </LoadNotesTableTd>
                <LoadNotesTableTd paddingLeft={0}>
                  <Input
                    className="form-control"
                    value={loadNote.weight || ''}
                    isDisabled={!isInEditMode}
                    placeholder="Add weight"
                    width="147px"
                    type="number"
                    onChange={e => handleChange(index, 'weight', e.target.value)}
                  />
                </LoadNotesTableTd>
              </>
            ) : (
              ''
            )}
            <LoadNotesTableTd colSpan={state.showProductCategoryCols ? 3 : 1} paddingRight="1em">
              <ButtonGroup gap="4" alignItems="center" justifyContent="flex-end" width="100%">
                <IconButton
                  onClick={handleAdd}
                  isDisabled={!isInEditMode}
                  icon={
                    <i
                      className="la la-plus international-order-line__icon--orange"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  }
                  colorScheme="actionSecondary"
                  height="25px"
                  minWidth="25px"
                  borderRadius="32px"
                />
                <IconButton
                  variant="ghost"
                  icon={<TrashOutlineIconSVG />}
                  isDisabled={!isInEditMode}
                  onClick={() => {
                    handleDelete(index);
                  }}
                />
              </ButtonGroup>
            </LoadNotesTableTd>
          </Tr>
        ))}
        {loadNotes.length < 1 ? (
          <Tr>
            <LoadNotesTableTd colSpan={5} paddingRight={0}>
              <Flex>
                <IconButton
                  marginLeft="auto"
                  onClick={handleAdd}
                  isDisabled={!isInEditMode}
                  icon={
                    <i
                      className="la la-plus international-order-line__icon--orange"
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  }
                  colorScheme="actionSecondary"
                  height="25px"
                  minWidth="25px"
                  borderRadius="32px"
                />
              </Flex>
            </LoadNotesTableTd>
          </Tr>
        ) : (
          ''
        )}
      </Tbody>
    </Table>
  );
};

LoadNotes.propTypes = {
  inputProducts: PropTypes.arrayOf(Object),
  isInEditMode: PropTypes.bool,
  packerPlantOriginCountry: PropTypes.string,
  loadNotes: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default LoadNotes;
