import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HStack, Flex, Heading, Box, TabPanels, Tabs, TabPanel } from '@chakra-ui/react';
import { debounce } from 'lodash';
import RightAngleIcon from '../../core/RightAngleIcon';
import SideBar from './SideBar';
import TabButtons from './TabButtons';
import EstablishmentDetails from './EstablishmentDetails';
import {
  fetchOperationalPackers,
  fetchEstablishmentDetailsList,
  fetchEstablishmentOptions,
} from '../../../reducers/reducer_establishment_details';
import NoData from '../_components/NoData';
import Loading from '../../basic/Loading/Loading';

const Establishments = () => {
  const dispatch = useDispatch();
  const { establishmentDetailsState, loading } = useSelector(state => ({
    establishmentDetailsState: state.establishmentDetails,
    loading: state.establishmentDetails.loading,
  }));
  const [packer, setPacker] = useState(null);
  const [establishmentDetailList, setEstablishmentDetailList] = useState([]);
  const [packerSearch, setPackerSearch] = useState({
    name: '',
  });

  useEffect(() => {
    dispatch(fetchOperationalPackers());
    dispatch(fetchEstablishmentOptions());
  }, []);

  const fetchOperationalPackerHandler = useCallback(
    debounce(pState => dispatch(fetchOperationalPackers(pState)), 300),
    []
  );

  useEffect(() => {
    if (establishmentDetailsState?.packers.length > 0) {
      setPacker(establishmentDetailsState?.packers[0]);
    }
  }, [establishmentDetailsState?.packers]);

  useEffect(() => {
    if (packer !== null) {
      dispatch(fetchEstablishmentDetailsList(packer.id));
    }
  }, [packer]);

  useEffect(() => {
    setEstablishmentDetailList(establishmentDetailsState?.estblishmentDetailList);
  }, [establishmentDetailsState?.estblishmentDetailList]);

  useEffect(() => {
    fetchOperationalPackerHandler({ ...packerSearch });
  }, [packerSearch?.name]);

  const handleSearch = value => {
    setPackerSearch({ name: value });
  };

  const changePacker = _packer => {
    setPacker(_packer);
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" width="100%" marginBlock="20px">
        <HStack spacing="10px">
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Establishments
          </Heading>
        </HStack>
      </Flex>
      <Flex height="calc(100vh - 320px)" gap="40px" css={{ gap: '40px' }}>
        <SideBar packerId={packer?.id} changePacker={changePacker} handleSearch={handleSearch} />
        <Box
          w="calc(100% - 350px)"
          flexBasis="calc(100% - 350px)"
          h="100%"
          padding="10px 20px 0px"
          borderRadius="12px"
          border="1px solid #F7F7F7"
          background="#FFF"
          boxShadow="0px 0px 4px 0px rgba(170, 169, 184, 0.20)"
        >
          {loading ? (
            <Loading />
          ) : (
            <Tabs height="100%">
              <TabButtons establishmentDetailList={establishmentDetailList} />
              <TabPanels
                height="calc(100% - 38px)"
                overflowY="auto"
                css={{
                  '&::-webkit-scrollbar': {
                    height: '0px',
                    width: '0px',
                  },
                  '&::-webkit-scrollbar-track': {
                    height: '0px',
                    width: '0px',
                    background: 'var(--chakra-colors-gray-100)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'var(--chakra-colors-nav-scrollBarBackgroundColor)',
                  },
                }}
              >
                {establishmentDetailList && establishmentDetailList.length ? (
                  establishmentDetailList.map(estDetail => {
                    return (
                      <TabPanel paddingInline={0} height="100%">
                        <EstablishmentDetails packer={packer} estDetail={estDetail} />
                      </TabPanel>
                    );
                  })
                ) : (
                  <NoData />
                )}
              </TabPanels>
            </Tabs>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default Establishments;
