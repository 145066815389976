import React, { useState, useEffect } from 'react';
import { message, Upload } from 'antd';
import { Box, Flex, FormLabel, IconButton, Skeleton, Text, useToast } from '@chakra-ui/react';
import { MdOutlineCloudUpload, MdOutlineInsertDriveFile } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import PropTypes from 'prop-types';
import formatBytes from '../../../helpers/formatBytes';
import { previewFromS3Link } from '../../../helpers/downloads';

const { Dragger } = Upload;

const FileUploader = ({ setFieldValue, fieldName, label, fileObject } = { label: 'Upload File' }) => {
  const toast = useToast();
  const [customFile, setCustomFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = info => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const beforeUpload = file => {
    // check the file type - you can specify the types you'd like here:
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      toast({
        title: 'Error',
        description: 'You can only upload PDF file!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    // check the file size - you can specify the file size you'd like here:
    const isLt20M = file.size / 1024 / 1024 <= 20;
    if (!isLt20M) {
      toast({
        title: 'Error',
        description: 'You can only upload less then 20MB file!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (typeof fileObject === 'string') {
      setIsLoading(true);
      const fileName = fileObject.substring(fileObject.lastIndexOf('/') + 1);
      previewFromS3Link(fileObject).then(url => {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const fl = new File([blob], fileName || 'filename', { type: 'application/pdf' });
            setCustomFile(fl);
          })
          .catch(error => {
            toast({
              title: 'Error',
              description: error.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  }, []);

  return (
    <Box className="file-uploader">
      <FormLabel>{label}</FormLabel>
      {isLoading ? (
        <Skeleton width="100%" height="60px" />
      ) : (
        <>
          {(fileObject && fileObject?.name) || customFile ? (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              padding="15px"
              border="1px solid #EFEFEF"
              borderRadius="5px"
              fontSize="12px"
              color="#00000099"
              marginTop="20px"
              position="relative"
            >
              <Flex gap={3} css={{ gap: '10px' }} alignItems="center">
                <MdOutlineInsertDriveFile size="26px" />
                <Text>{decodeURI(fileObject?.name || customFile?.name)}</Text>
              </Flex>
              <Text>{formatBytes(fileObject?.size || customFile?.size)}</Text>
              <IconButton
                icon={<RxCross2 size="12px" color="#fff" />}
                background="#FD5C38"
                padding="0"
                height="24px"
                minW="24px"
                w="24px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                position="absolute"
                top="-10px"
                right="-10px"
                _hover={{ background: '#FD5C38' }}
                _focus={{ background: '#FD5C38' }}
                _active={{ background: '#FD5C38' }}
                onClick={() => {
                  setFieldValue(fieldName, null);
                  setCustomFile(null);
                }}
              />
            </Flex>
          ) : (
            <Dragger
              accept=".pdf"
              padding="0"
              name="file"
              multiple={false}
              customRequest={({ file }) => {
                setFieldValue(fieldName, file);
              }}
              onChange={onChangeHandler}
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                borderRadius="4px"
                border="1px dashed #C9CFD7"
                padding="36px"
                textAlign="center"
                cursor="pointer"
              >
                <Flex flexDir="column" align="center" fontSize="14px" color="#00000061" userSelect="none">
                  <MdOutlineCloudUpload size="30px" />
                  <Text marginTop="10px">
                    <Text as="span" color="#3A44B0">
                      Click here to upload
                    </Text>
                    &nbsp;or drag & drop
                  </Text>
                  <Text>Supported file types are .pdf Max file size supported is 20 MB</Text>
                </Flex>
              </Flex>
            </Dragger>
          )}
        </>
      )}
    </Box>
  );
};

FileUploader.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fileObject: PropTypes.object,
};

export default FileUploader;
