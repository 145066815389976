import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const ContactInfo = ({ data }) => {
  return (
    <Box marginBottom="15px">
      <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43" marginBottom="15px">
        Contact Information
      </Text>
      <Table
        pagination={{
          pageSize: 10,
          current: 1,
          style: { display: 'none' },
        }}
        rowClassName="food-safety-table-row"
        className="food-safety-table"
        showHeader={false}
        dataSource={data || []}
        columns={[
          {
            dataIndex: 'info_type',
            key: 'info_type',
          },
          {
            dataIndex: 'fullname',
            key: 'fullname',
          },
          {
            dataIndex: 'mobile',
            key: 'mobile',
          },
          {
            dataIndex: 'email',
            key: 'email',
            render: email => (
              <Link
                _hover={{ color: 'inherit', textDecoration: 'underline' }}
                _active={{ color: 'inherit', textDecoration: 'underline' }}
                textDecoration="underline"
                href={`mailto:${email}`}
              >
                {email}
              </Link>
            ),
          },
        ]}
      />
    </Box>
  );
};

ContactInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      info_type: PropTypes.string,
      fullname: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
    })
  ),
};

export default ContactInfo;
