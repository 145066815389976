import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, SearchIcon, ViewIcon, ArrowBackIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { Alert } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import FileViewer from 'react-file-viewer';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import xlsxViewer from 'xlsx-viewer';
import 'xlsx-viewer/lib/index.css';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import { fetchConfigs } from '../../../actions/actions_config';
import Select from '../../../components/basic/SelectField';
import RightAngleIcon from '../../../components/core/RightAngleIcon';
import { selectConfigs } from '../../../reducers/reducer_config';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import { fetchEntitiesData, selectEntities } from '../../../slices/masterData/entityManagerSlice';
import { getInternalPoLabel } from "../../../utils";
import './documents-queue.scss';


const PO_FROM = {
  grinder_po_number: 'grinder_po_number',
  internal_po_number: 'internal_po_number',
  packer_so_number: 'packer_so_number'
}

const EMAIL_ATTACHMENTS_STATUS = {
  pending: 'pending',
  pendingRevision: 'pending-revision',
  completed: 'completed',
  rejected: 'rejected',
  archive: 'archive',
  draft: 'draft',
  all: 'all',
}

const EMAIL_ATTACHMENTS_STATUS_COLOR = {
  [EMAIL_ATTACHMENTS_STATUS.pending]: 'yellow',
  [EMAIL_ATTACHMENTS_STATUS.pendingRevision]: 'yellow',
  [EMAIL_ATTACHMENTS_STATUS.completed]: 'green',
  [EMAIL_ATTACHMENTS_STATUS.rejected]: 'red',
  [EMAIL_ATTACHMENTS_STATUS.archive]: 'yellow',
  [EMAIL_ATTACHMENTS_STATUS.draft]: 'yellow',
}
const STATUS_FILTER = [
  { label: 'All', value: EMAIL_ATTACHMENTS_STATUS.all, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M2 8.66667V7.33334H11.3333V8.66667H2ZM2 12.6667V11.3333H11.3333V12.6667H2ZM2 4.66667V3.33334H11.3333V4.66667H2ZM13.3333 5.33334V3.33334H12.6667V2.66667H14V5.33334H13.3333ZM12.6667 11.3333V10.6667H14.6667V13.3333H12.6667V12.6667H14V12.3333H13.3333V11.6667H14V11.3333H12.6667ZM14.1667 6.66667C14.4467 6.66667 14.6667 6.89334 14.6667 7.16667C14.6667 7.30001 14.6133 7.42667 14.5267 7.51334L13.4133 8.66667H14.6667V9.33334H12.6667V8.72001L14 7.33334H12.6667V6.66667H14.1667Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
  { label: 'Pending', value: EMAIL_ATTACHMENTS_STATUS.pending, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M2.66671 1.33333C2.31309 1.33333 1.97395 1.4738 1.7239 1.72385C1.47385 1.9739 1.33337 2.31304 1.33337 2.66666V13.3333C1.33337 13.687 1.47385 14.0261 1.7239 14.2761C1.97395 14.5262 2.31309 14.6667 2.66671 14.6667H8.27337C8.99626 15.1011 9.82335 15.3315 10.6667 15.3333C11.9044 15.3333 13.0914 14.8417 13.9665 13.9665C14.8417 13.0913 15.3334 11.9043 15.3334 10.6667C15.3322 9.66263 15.0072 8.68574 14.4067 7.88107C13.8062 7.0764 12.9622 6.48686 12 6.2V5.33333L8.00004 1.33333H2.66671ZM2.66671 2.66666H7.33337V6H10.6667C9.42903 6 8.24205 6.49166 7.36688 7.36683C6.49171 8.242 6.00004 9.42898 6.00004 10.6667C6.00093 11.6205 6.29409 12.5512 6.84004 13.3333H2.66671V2.66666ZM10.6667 7.33333C11.5508 7.33333 12.3986 7.68452 13.0237 8.30964C13.6489 8.93476 14 9.78261 14 10.6667C14 11.5507 13.6489 12.3986 13.0237 13.0237C12.3986 13.6488 11.5508 14 10.6667 14C9.78265 14 8.93481 13.6488 8.30968 13.0237C7.68456 12.3986 7.33337 11.5507 7.33337 10.6667C7.33337 9.78261 7.68456 8.93476 8.30968 8.30964C8.93481 7.68452 9.78265 7.33333 10.6667 7.33333ZM10 8V11.3333L12.4067 12.7733L12.9067 11.96L11 10.8333V8H10Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
  { label: 'Completed', value: EMAIL_ATTACHMENTS_STATUS.completed, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M15.6666 11.3333L12.3333 14.6667L9.99996 12.3333L11 11.3333L12.3333 12.6667L14.6666 10.3333L15.6666 11.3333ZM8.72663 13.3333H3.99996V2.66667H8.66663V6H12V8.72667C12.22 8.69334 12.44 8.66667 12.6666 8.66667C12.8933 8.66667 13.1133 8.69334 13.3333 8.72667V5.33334L9.33329 1.33334H3.99996C3.25996 1.33334 2.66663 1.93334 2.66663 2.66667V13.3333C2.66663 14.0733 3.25996 14.6667 3.99996 14.6667H9.20663C8.97329 14.26 8.80663 13.8133 8.72663 13.3333Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
  { label: 'Rejected', value: EMAIL_ATTACHMENTS_STATUS.rejected, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M15.0266 14.08L13.6066 12.6667L15.0266 11.2533L14.08 10.3067L12.6666 11.7267L11.2533 10.3067L10.3066 11.2533L11.7266 12.6667L10.3066 14.08L11.2533 15.0267L12.6666 13.6067L14.08 15.0267M3.99996 1.33334C3.25996 1.33334 2.66663 1.93334 2.66663 2.66667V13.3333C2.66663 14.0733 3.25996 14.6667 3.99996 14.6667H9.20663C8.96663 14.2533 8.79996 13.8 8.71996 13.3333H3.99996V2.66667H8.66663V6H12V8.72C12.22 8.68667 12.4466 8.66667 12.6666 8.66667C12.8933 8.66667 13.1133 8.68667 13.3333 8.72V5.33334L9.33329 1.33334M5.33329 8V9.33334H10.6666V8M5.33329 10.6667V12H8.66663V10.6667H5.33329Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
  { label: 'Pending Revision', value: EMAIL_ATTACHMENTS_STATUS.pendingRevision, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M3.99996 1.33333C3.64663 1.33333 3.30663 1.47333 3.05996 1.72667C2.80663 1.97333 2.66663 2.31333 2.66663 2.66667V13.3333C2.66663 13.6867 2.80663 14.0267 3.05996 14.2733C3.30663 14.5267 3.64663 14.6667 3.99996 14.6667H8.66663C8.41996 14.2667 8.22663 13.8133 8.11329 13.3333H3.99996V2.66667H8.66663V6H12V8H12.3333C12.6666 8 13 8.04 13.3333 8.11333V5.33333L9.33329 1.33333H3.99996ZM7.99996 12C8.04663 11.5333 8.15996 11.08 8.33329 10.6667H5.33329V12H7.99996ZM9.20663 9.33333C9.61996 8.90667 10.1133 8.56667 10.6666 8.33333V8H5.33329V9.33333H9.20663ZM12 9.66667C12.74 9.66667 13.4066 9.96667 13.8866 10.4467L14.6666 9.66667V12.3333H12L13.18 11.1533C12.88 10.8533 12.46 10.6667 12 10.6667C11.08 10.6667 10.3333 11.4133 10.3333 12.3333C10.3333 13.2533 11.08 14 12 14C12.5466 14 13.0266 13.74 13.3333 13.3333H14.4733C14.08 14.3133 13.12 15 12 15C10.5266 15 9.33329 13.8067 9.33329 12.3333C9.33329 10.86 10.5266 9.66667 12 9.66667Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
  { label: 'Save Draft', value: EMAIL_ATTACHMENTS_STATUS.draft, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M5.33329 8.0022H10.6666V9.33553H5.33329V8.0022ZM6.66663 13.3355H3.99996V2.66886H8.66663V6.0022H12V8.06886L13.3333 6.73553V5.33553L9.33329 1.33553H3.99996C3.64634 1.33553 3.3072 1.47601 3.05715 1.72605C2.8071 1.9761 2.66663 2.31524 2.66663 2.66886V13.3355C2.66663 13.6892 2.8071 14.0283 3.05715 14.2783C3.3072 14.5284 3.64634 14.6689 3.99996 14.6689H6.66663V13.3355ZM5.33329 12.0022H8.06663L8.66663 11.4022V10.6689H5.33329V12.0022ZM13.4666 8.66886C13.5333 8.66886 13.6666 8.73553 13.7333 8.8022L14.6 9.66886C14.7333 9.8022 14.7333 10.0689 14.6 10.2022L13.9333 10.8689L12.5333 9.46886L13.2 8.8022C13.2666 8.73553 13.3333 8.66886 13.4666 8.66886ZM13.4666 11.2689L9.39996 15.3355H7.99996V13.9355L12.0666 9.86886L13.4666 11.2689Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
  { label: 'Archive', value: EMAIL_ATTACHMENTS_STATUS.archive, icon: active => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M13.3334 12H2.66671V5.33333H13.3334M13.3334 4H8.00004L6.66671 2.66667H2.66671C1.92671 2.66667 1.33337 3.26 1.33337 4V12C1.33337 12.3536 1.47385 12.6928 1.7239 12.9428C1.97395 13.1929 2.31309 13.3333 2.66671 13.3333H13.3334C13.687 13.3333 14.0261 13.1929 14.2762 12.9428C14.5262 12.6928 14.6667 12.3536 14.6667 12V5.33333C14.6667 4.59333 14.0667 4 13.3334 4Z" fill={active ? '#3A44B0' : 'black'} fill-opacity="0.6" /></svg> },
]
const STATUS_OPTIONS = [
  { label: 'Pending', value: EMAIL_ATTACHMENTS_STATUS.pending },
  { label: 'Pending Revision', value: EMAIL_ATTACHMENTS_STATUS.pendingRevision },
  { label: 'Completed', value: EMAIL_ATTACHMENTS_STATUS.completed },
  { label: 'Rejected', value: EMAIL_ATTACHMENTS_STATUS.rejected },
  { label: 'Archived', value: EMAIL_ATTACHMENTS_STATUS.archive },
]

export default () => {
  const { token } = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const configs = useSelector(selectConfigs);
  const entities = useSelector(selectEntities);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [grinders, setGrinders] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formFields, setFormFields] = useState({});
  const toast = useToast();
  const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose, } = useDisclosure()
  const xlsxViewerEle = useRef();


  const [axiosConfig, setAxiosConfig] = useState();
  const [attachments, setAttachments] = useState();
  const [statusFacet, setStatusFacet] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState();
  const [poNumberFilter, setPoNumberFilter] = useState('');
  const [docTypeFilter, setDocTypeFilter] = useState([]);
  const [prodTypeFilter, setProdTypeFilter] = useState([]);
  const [grinderUidFilter, setGrinderUidFilter] = useState([]);
  const [originCountryFilter, setOriginCountryFilter] = useState([]);
  const [purchasingOfficeFilter, setPurchasingOfficeFilter] = useState([]);
  const [fromEmailAddressFilter, setFromEmailAddressFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(EMAIL_ATTACHMENTS_STATUS.pending);
  const [sortFilter, setSortFilter] = useState('');
  const [isLoading, setIsLoading] = useState();
  const [selectedAttachment, setSelectedAttachment] = useState();
  const [filePreviewZoom, setFilePreviewZoom] = useState(100);

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}'
    })
    dispatch(fetchConfigs(token));
    dispatch(fetchEntitiesData(['form_fields']));
    loadDocumentTypes();
    loadGrinders();
    loadCountries();
  }, [token]);

  useEffect(() => {
    if (!entities?.form_fields?.entities) {
      return
    }
    setFormFields(entities.form_fields.entities.reduce((o, c) => {
      o[c.type] = o[c.type] || []
      o[c.type].push(c)
      return o
    }, {}))
  }, [entities])


  const loadAttachments = async () => {
    axios.get(`${Constants.URL}emails/attachments?page=${currentPage + 1}&status=${statusFilter}&doc_types=${docTypeFilter.join(',')}&prod_types=${prodTypeFilter.join(',')}&from_email_address=${fromEmailAddressFilter}&po_number=${poNumberFilter}&grinder_uids=${grinderUidFilter.join(',')}&origin_countries=${originCountryFilter.join(',')}&purchasing_office=${purchasingOfficeFilter.join(',')}&sort=${sortFilter}`, axiosConfig).then(response => {
      setAttachments(response.data.items)
      setPagesCount(response.data.pagesCount)
      setStatusFacet(response.data.facets.status)
    })
  }

  useEffect(() => {
    loadAttachments();
  }, [statusFilter, sortFilter, currentPage])

  const loadDocumentTypes = async () => axios.get(`${Constants.URL}document-types`, axiosConfig).then(response => setDocumentTypes(response.data))

  const loadGrinders = () => axios.get(`${Constants.URL}grinders`, axiosConfig).then(response => setGrinders(response.data.map(g => ({ label: g.name, value: g.uid }))));

  const loadCountries = () => axios.get(`${Constants.URL}countries?origin_country=true`, axiosConfig).then(response => setCountries(response.data.map(c => ({ label: c.name, value: c.name }))));

  const handleError = (isSystemError, msg = '') => toast({
    title: 'An error occurred.',
    position: 'top-right',
    description: `${isSystemError ? 'Please contact administrator with the screenshot of this error.' : ''}${msg}${isSystemError ? ` at ${moment().toISOString()}` : ''}`,
    status: 'error',
    duration: null,
    isClosable: true,
  })

  const handleSuccess = (msg = '') => toast({
    title: msg,
    position: 'top-right',
    status: 'success',
    isClosable: true,
  });

  const setAttachmentLoading = (a, isLoading) => {
    const ats = [...attachments]
    for (let i = 0; i < ats.length; i++) {
      const at = ats[i];
      if (at === a) {
        at.isLoading = isLoading
        break
      }
    }
    setAttachments(ats)
  }

  const { errors, handleChange, handleBlur, setFieldValue, handleSubmit, setValues, values, touched, isValid } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      doc_type: Yup.string().nullable().required('Required'),
      po_lookup_field: Yup.string().nullable().required('Required'),
      po_number: Yup.string().nullable().required('Required'),
      status: Yup.string().nullable().required('Required'),
      notes: Yup.string().nullable(),
      is_draft: Yup.bool().nullable(),
    }),
    onSubmit: data => saveAttachment(data),
  });

  const openAttachmentView = async a => {
    setAttachmentLoading(a, true)
    try {
      setValues(a)
      const { signed_url } = (await axios.post(`${Constants.URL}signed_url`, [{ url: a.url }], axiosConfig)).data[0];
      const res = await fetch(signed_url)
      if (!res.ok) {
        if (res.status === 404) {
          throw new Error('Could not load the attachment.')
        }
      }
      const extension = a.filename.split('.').slice(-1)[0]
      const selectedAttachment = { ...a, signedUrl: signed_url, fileType: extension }
      if (extension !== 'xlsx') {
        selectedAttachment.render = 'react-file-viewer'
      } else {
        selectedAttachment.render = 'xlsx-viewer'
        const blob = await res.blob()
        setTimeout(() => {
          xlsxViewer.renderXlsx(blob, xlsxViewerEle.current, {
            initialSheetIndex: 0,
            onLoad() { },
            onRender() { },
            onSwitch() { }
          })
        }, 1)
      }
      setFilePreviewZoom(100)
      setSelectedAttachment(selectedAttachment)
      onViewOpen()
    } catch (e) {
      handleError(true, e)
    } finally {
      setAttachmentLoading(a, false)
    }
  }

  // SAVE/UPLOAD THE ATTACHMENT
  const saveAttachment = async attachment => {
    setIsLoading(true)
    try {
      const { id, doc_type, po_lookup_field, po_number, notes, is_draft, status } = attachment
      await axios.patch(`${Constants.URL}emails/attachments/${id}`, { doc_type, po_lookup_field, po_number, notes, is_draft, status }, axiosConfig)
      await loadAttachments()
      onViewClose()
      handleSuccess('Attachment saved succesfully!')
    } catch (e) {
      if (e.response) {
        handleError(false, e?.response?.data?.error || e)
      } else {
        handleError(true, e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box className='documents-queue' margin="2em">
      <HStack spacing="10px" justifyContent="space-between" mb={6}>
        <HStack><RightAngleIcon /><Heading className="dashboard-section__heading" fontSize="15px">Documents Queue</Heading></HStack>
        <HStack className="filters">
          <Box>
            <InputGroup size='md'>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon color='gray.300' />
              </InputLeftElement>
              <Input name='po_number' width="200px" type="text" placeholder='PO Number' onChange={e => setPoNumberFilter(e.target.value)} value={poNumberFilter} />
            </InputGroup>
          </Box>
          {/* DOCUMENT TYPE */}
          <Box>
            <Menu closeOnSelect={false} >
              <MenuButton as={Button}
                variant="outline"
                colorScheme="gray.200"
                color="gray.500"
                borderColor="gray.200"
                rightIcon={<ChevronDownIcon />}>
                Document Type
              </MenuButton>
              <MenuList>
                <Stack py={8} px={6} spacing={4}>
                  {documentTypes?.map(({ name }) => <Checkbox key={name} isChecked={docTypeFilter.includes(name)} onChange={(e) => setDocTypeFilter(e.target.checked ? [...docTypeFilter, name] : docTypeFilter.filter(i => i !== name))}>{name}</Checkbox>)}
                </Stack>
              </MenuList>
            </Menu>
          </Box>
          {/* PRODUCT TYPE */}
          <Box>
            <Menu closeOnSelect={false} >
              <MenuButton as={Button}
                variant="outline"
                colorScheme="gray.200"
                color="gray.500"
                borderColor="gray.200"
                rightIcon={<ChevronDownIcon />}>
                Product Type
              </MenuButton>
              <MenuList>
                <Stack py={8} px={6} spacing={4}>
                  {formFields?.product_types?.map(({ value }) => <Checkbox key={value} isChecked={prodTypeFilter.includes(value)} onChange={(e) => setProdTypeFilter(e.target.checked ? [...prodTypeFilter, value] : prodTypeFilter.filter(i => i !== value))}>{value}</Checkbox>)}
                </Stack>
              </MenuList>
            </Menu>
          </Box>
          {/* GRINDER */}
          <Box>
            <Menu closeOnSelect={false} >
              <MenuButton as={Button}
                variant="outline"
                colorScheme="gray.200"
                color="gray.500"
                borderColor="gray.200"
                rightIcon={<ChevronDownIcon />}>
                Grinder
              </MenuButton>
              <MenuList>
                <Stack py={8} px={6} spacing={4}>
                  {grinders?.map(({ label, value }) => <Checkbox key={value} isChecked={grinderUidFilter.includes(value)} onChange={(e) => setGrinderUidFilter(e.target.checked ? [...grinderUidFilter, value] : grinderUidFilter.filter(i => i !== value))}>{label}</Checkbox>)}
                </Stack>
              </MenuList>
            </Menu>
          </Box>
          {/* OTHER */}
          <Box>
            <Menu closeOnSelect={false} >
              <MenuButton as={Button}
                variant="outline"
                colorScheme="gray.200"
                color="gray.500"
                borderColor="gray.200"
                rightIcon={<ChevronDownIcon />}>
                Other
              </MenuButton>
              <MenuList>
                <Stack py={8} px={6} spacing={4}>
                  {/* ORIGIN COUNTRY */}
                  <FormControl>
                    <FormLabel>Origin Country</FormLabel>
                    <Menu closeOnSelect={false} >
                      <MenuButton as={Button}
                        variant="outline"
                        colorScheme="gray.200"
                        color="gray.500"
                        borderColor="gray.200"
                        rightIcon={<ChevronDownIcon />}>
                        Select Origin Country
                      </MenuButton>
                      <MenuList>
                        <Stack py={8} px={6} spacing={4}>
                          {countries?.map(({ value, label }) => <Checkbox key={value} isChecked={originCountryFilter.includes(value)} onChange={(e) => setOriginCountryFilter(e.target.checked ? [...originCountryFilter, value] : originCountryFilter.filter(i => i !== value))}>{label}</Checkbox>)}
                        </Stack>
                      </MenuList>
                    </Menu>
                  </FormControl>
                  {/* PURCHASING OFFICE */}
                  <FormControl>
                    <FormLabel>Purchasing Office</FormLabel>
                    <Menu closeOnSelect={false} >
                      <MenuButton as={Button}
                        variant="outline"
                        colorScheme="gray.200"
                        color="gray.500"
                        borderColor="gray.200"
                        rightIcon={<ChevronDownIcon />}>
                        Select Purchasing Office
                      </MenuButton>
                      <MenuList>
                        <Stack py={8} px={6} spacing={4}>
                          {formFields?.business_office?.map(({ value }) => <Checkbox key={value} isChecked={purchasingOfficeFilter.includes(value)} onChange={(e) => setPurchasingOfficeFilter(e.target.checked ? [...purchasingOfficeFilter, value] : purchasingOfficeFilter.filter(i => i !== value))}>{value}</Checkbox>)}
                        </Stack>
                      </MenuList>
                    </Menu>
                  </FormControl>
                  <FormControl>
                    <FormLabel>From Email</FormLabel>
                    <Input name='from_email' type="text" onChange={e => setFromEmailAddressFilter(e.target.value)} value={fromEmailAddressFilter} />
                  </FormControl>
                </Stack>
              </MenuList>
            </Menu>
          </Box>
          {/* FILTER */}
          <Button colorScheme='blue' width="100px" onClick={loadAttachments}>Filter</Button>
        </HStack>
      </HStack>
      <HStack alignItems="normal">
        <Box className='facets'>
          {STATUS_FILTER.map(f => (
            <div className={['facet', statusFilter === f.value ? 'active' : ''].join(' ')} key={f.label} onClick={() => { setStatusFilter(f.value); setAttachments(null) }}>
              <span>{f.icon(statusFilter === f.value)}</span>
              <span className="label">{f.label}</span>
              <Badge colorScheme="primary">{statusFacet[f.value] || 0}</Badge>
            </div>
          ))}
        </Box>
        <Box className='results'>

          {Array.isArray(attachments) && (
            <>
              {attachments.length > 0 ? (
                <TableContainer style={{ width: '100%' }}>
                  <Table variant='simple' style={{ 'minHeight': '200px' }}>
                    <Thead>
                      <Tr>
                        <Th>SUBJECT /EMAIL /TYPE</Th>
                        <Th>DOCUMENT NAME <ArrowUpIcon onClick={() => setSortFilter('filename|asc')} /><ArrowDownIcon onClick={() => setSortFilter('filename|desc')} /></Th>
                        <Th>PO NUMBER <ArrowUpIcon onClick={() => setSortFilter('po_number|asc')} /><ArrowDownIcon onClick={() => setSortFilter('po_number|desc')} /></Th>
                        <Th>PO LOOKUP FIELD</Th>
                        <Th>RECEIVED DATE <ArrowUpIcon onClick={() => setSortFilter('created|asc')} /><ArrowDownIcon onClick={() => setSortFilter('created|desc')} /></Th>
                        <Th>STATUS</Th>
                        <Th>ACTION</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {attachments && attachments.map(a => (
                        <Tr key={a.id}>
                          <Td>
                            <b>{a.email?.subject}</b><br />
                            {a.email?.from_email?.value?.[0].address} <Badge colorScheme='purple'>{a.doc_type}</Badge>
                          </Td>
                          <Td>{a.filename}</Td>
                          <Td><a style={{ textDecorationStyle: 'solid', textDecorationLine: 'underline' }} href={`/order/search?${a.po_lookup_field}=${a.po_number}&po_lookup_field=${a.po_lookup_field}&auto_open_po_card_purchase_order_id=${a.purchase_order_id}`} target='_blank'>{a.po_number}</a></Td>
                          <Td>{a.po_lookup_field?.replaceAll('_', ' ')}</Td>
                          <Td>{moment(a.created).format('MM-DD-YYYY h:mm a')}</Td>
                          <Td>
                            <Badge colorScheme={EMAIL_ATTACHMENTS_STATUS_COLOR[a.status]}>{a.is_draft ? 'Draft/' : ''}{a.status}</Badge>
                          </Td>
                          <Td><Button leftIcon={<ViewIcon />} isLoading={a.isLoading} onClick={() => openAttachmentView(a)} variant='outline'></Button></Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  {attachments && <div className="custom_pagination">
                    <ReactPaginate
                      forcePage={currentPage}
                      pageCount={pagesCount}
                      pageRangeDisplayed={pagesCount < 8 ? pagesCount : 8}
                      marginPagesDisplayed={0}
                      onPageChange={page => setCurrentPage(page?.selected || 0)}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                      previousLabel={<i className="fa fa-angle-left" />}
                      nextLabel={<i className="fa fa-angle-right" />}
                    />
                    <div className="pagination-stats">
                      {currentPage + 1} / {pagesCount}
                    </div>
                  </div>}
                </TableContainer>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                    <path d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z" fill="url(#paint0_linear_8992_68753)" />
                    <path d="M120 150H30V53C34.242 52.9952 38.3089 51.308 41.3084 48.3085C44.308 45.3089 45.9952 41.242 46 37H104C103.996 39.1014 104.408 41.1828 105.213 43.1238C106.018 45.0648 107.2 46.8268 108.691 48.308C110.172 49.7991 111.934 50.9816 113.875 51.787C115.817 52.5924 117.898 53.0047 120 53V150Z" fill="white" />
                    <path d="M75 102C88.2548 102 99 91.2548 99 78C99 64.7452 88.2548 54 75 54C61.7452 54 51 64.7452 51 78C51 91.2548 61.7452 102 75 102Z" fill="#4285F4" />
                    <path d="M83.4853 89.3138L75 80.8286L66.5147 89.3138L63.6863 86.4854L72.1716 78.0001L63.6863 69.5148L66.5147 66.6864L75 75.1717L83.4853 66.6864L86.3137 69.5148L77.8284 78.0001L86.3137 86.4854L83.4853 89.3138Z" fill="white" />
                    <path d="M88 108H62C60.3431 108 59 109.343 59 111C59 112.657 60.3431 114 62 114H88C89.6569 114 91 112.657 91 111C91 109.343 89.6569 108 88 108Z" fill="#DFEAFB" />
                    <path d="M97 120H53C51.3431 120 50 121.343 50 123C50 124.657 51.3431 126 53 126H97C98.6569 126 100 124.657 100 123C100 121.343 98.6569 120 97 120Z" fill="#DFEAFB" />
                    <defs>
                      <linearGradient id="paint0_linear_8992_68753" x1="75" y1="0" x2="75" y2="150" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#E3ECFA" />
                        <stop offset="1" stop-color="#DAE7FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div><br /><b>No Data!</b></div>
                  <div style={{ textAlign: 'center' }}>The {statusFilter} is empty; there is no<br />data available.</div>
                </div>
              )}
            </>
          )}

        </Box>
      </HStack >
      {/* VIEW MODAL */}
      < Modal isOpen={isViewOpen} size='full' onClose={onViewClose} isCentered >
        <ModalOverlay />
        <ModalContent>
          <form className="documents-queue-view-modal" onSubmit={handleSubmit}>
            <Box className="header">
              <ArrowBackIcon mt={6} ml={7} onClick={onViewClose} boxSize={6} />
              <ModalCloseButton className="close-btn" />
            </Box>
            <Flex className="main">
              <Box className="left" >
                {selectedAttachment && (
                  <>
                    <div className="left-top" alignItems="center">
                      <Flex justifyContent="space-between" mb={2}>
                        <div>
                          <span className="email-subject">{selectedAttachment?.email?.subject}</span>
                          <Badge ml={2} colorScheme={EMAIL_ATTACHMENTS_STATUS_COLOR[selectedAttachment?.status]}>{selectedAttachment?.is_draft ? 'Draft/' : ''}{selectedAttachment?.status}</Badge>
                        </div>
                        <span className="email-attachment-created">{moment(selectedAttachment?.created).format('MM-DD-YYYY h:mm a')}</span>
                      </Flex>
                      <Flex alignItems="center" className="box-border">
                        <Avatar name={selectedAttachment?.email?.from_email?.value[0]?.name} />
                        <p><b>{selectedAttachment?.email?.from_email?.value[0]?.name}</b>{` <${selectedAttachment?.email?.from_email?.value[0]?.address}>`}</p>
                      </Flex>
                      <p>{selectedAttachment?.email?.text}</p>
                    </div>
                    <div className="left-bottom">
                      <div className="left-bottom-tile">Attached Document</div>
                      <HStack flexWrap="wrap">
                        <Flex alignItems="center" >
                          <span style={{ textOverflow: 'ellipsis', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }}>{selectedAttachment?.filename}</span>
                          <Button ml={2} leftIcon={<DownloadIcon />} onClick={() => window.open(selectedAttachment.signedUrl, '_blank')} variant='outline'></Button>
                        </Flex>
                        <Flex style={{ marginLeft: 'auto' }} alignItems="center">
                          <Box mr={2}>Zoom</Box>
                          <input type="range" min="100" max="400" step="10" value={filePreviewZoom} onChange={e => {
                            setFilePreviewZoom(e.target.value)
                            $("canvas").css("zoom", `${e.target.value}%`);
                          }} />
                        </Flex>
                      </HStack>
                      {selectedAttachment.render === 'react-file-viewer' && (
                        <FileViewer
                          fileType={selectedAttachment.fileType}
                          filePath={selectedAttachment.signedUrl}
                          onError={e => console.log('error', e)} />
                      )}
                      <div ref={xlsxViewerEle}></div>
                    </div>
                  </>
                )}
              </Box>
              <Box className="right">
                <Stack className="top" spacing={5}>
                  <FormControl id="doc_type" isInvalid={errors.doc_type && touched.doc_type}>
                    <FormLabel>Document type*</FormLabel>
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        isMulti: false,
                        value: ({ value: values.doc_type, label: values.doc_type }),
                        onChange: op => setFieldValue('doc_type', op.value),
                        options: documentTypes.map(({ name }) => ({ value: name, label: name })) || []
                      }}
                    />
                    {errors.doc_type && touched.doc_type && <Alert style={{ marginTop: '5px' }} message={errors.doc_type} type="error" />}
                  </FormControl>
                  <FormControl id="po_lookup_field" isInvalid={errors.po_lookup_field && touched.po_lookup_field}>
                    <FormLabel>PO Lookup Field*</FormLabel>
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        isMulti: false,
                        value: ({ value: values.po_lookup_field, label: PO_FROM[values.po_lookup_field]?.replaceAll('_', ' ') }),
                        onChange: op => {
                          setFieldValue('po_lookup_field', op.value)
                          setFieldValue('po_number', '')
                        },
                        options: Object.keys(PO_FROM).map(key => ({ value: key, label: PO_FROM[key]?.replaceAll('_', ' ') }))
                      }}
                    />
                    {errors.po_lookup_field && touched.po_lookup_field && <Alert style={{ marginTop: '5px' }} message={errors.po_lookup_field} type="error" />}
                  </FormControl>
                  <FormControl id="po_number" isInvalid={errors.po_number && touched.po_number}>
                    {PO_FROM.internal_po_number === values.po_lookup_field && <FormLabel>{getInternalPoLabel(configs)}*</FormLabel>}
                    {PO_FROM.packer_so_number === values.po_lookup_field && <FormLabel>Packer PO #*</FormLabel>}
                    {PO_FROM.grinder_po_number === values.po_lookup_field && <FormLabel>Grinder PO #*</FormLabel>}
                    <Input name='po_number' type="text" onChange={handleChange} onBlur={handleBlur} value={values.po_number} />
                    {errors.po_number && touched.po_number && <Alert style={{ marginTop: '5px' }} message={errors.po_number} type="error" />}
                  </FormControl>
                  {values.po_lookup_field && values.po_number && <div style={{ margin: '2px 3px' }}><a href={`/order/search?${values.po_lookup_field}=${values.po_number}`} target='_blank'>View Detail</a></div>}
                  <FormControl id="status" isInvalid={errors.status && touched.status}>
                    <FormLabel>Change Status</FormLabel>
                    <Select
                      {...{
                        isClearable: true,
                        isSearchable: true,
                        closeMenuOnSelect: true,
                        removeSelected: true,
                        isMulti: false,
                        value: ({ value: values.status, label: STATUS_OPTIONS.find(s => s.value === values.status)?.label }),
                        onChange: op => setFieldValue('status', op.value),
                        options: STATUS_OPTIONS.map(({ label, value }) => ({ value, label }))
                      }}
                    />
                    {errors.status && touched.status && <Alert style={{ marginTop: '5px' }} message={errors.status} type="error" />}
                  </FormControl>
                  {![EMAIL_ATTACHMENTS_STATUS.pending].includes(values.status) && (
                    <FormControl id="notes" isInvalid={errors.notes && touched.notes}>
                      <Textarea name='notes' onChange={handleChange} placeholder="Notes" onBlur={handleBlur} value={values.notes} />
                      {errors.notes && touched.reject_renotesason && <Alert style={{ marginTop: '5px' }} message={errors.notes} type="error" />}
                    </FormControl>
                  )}
                </Stack>
                <HStack className="bottom">
                  <Button onClick={() => window.open(`mailto:${selectedAttachment?.email?.from_email?.value?.[0]?.address}?subject=${selectedAttachment?.doc_type} (PO# ${selectedAttachment?.po_number})`, '_blank')} colorScheme='gray' mr={3} disabled={!isValid}>
                    Send Email
                  </Button>
                  <HStack>
                    {isLoading && <Box mr={3}><Spinner /></Box>}
                    <Button type="submit" onClick={() => setFieldValue('is_draft', true)} colorScheme='blue' variant='outline' mr={3} disabled={!isValid}>
                      Save Draft
                    </Button>
                    <Button type="submit" onClick={() => setFieldValue('is_draft', false)} colorScheme='blue' disabled={!isValid}>
                      Submit
                    </Button>
                  </HStack>
                </HStack>
              </Box>
            </Flex>
          </form>
        </ModalContent>
      </Modal >
    </Box >
  );
};
