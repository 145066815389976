import { Box, Flex, FormLabel, HStack, Switch, VStack, Wrap } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InternationalConfirmation from '../../../../containers/orders/InternationalConfirmation';
import { ProductTypeToggle } from '../../../basic/Toggle/Toggle';
import PurchaseOrderCard from '../PurchaseOrderCard/PurchaseOrderCard';
import RecurringSection from '../RecurringSection';
import USDomesticError from '../USPurchaseOrderForm';
import './InternationalOrderForm.scss';
import CustomFormControl from '../../../core/CustomFormControl';
import { withInternationalOrderFormContext } from './InternationalOrderFormContext';
import { filterInputProducts } from '../../../../utils';

class InternationalOrderForm extends Component {
  static propTypes = {
    baseForm: PropTypes.shape({
      grinderUid: PropTypes.string,
      packerId: PropTypes.string,
      packerPlantId: PropTypes.string,
      purchasingOffice: PropTypes.string,
      singleIngredientForm: PropTypes.bool,
      productType: PropTypes.string,
      voyage: PropTypes.shape({
        dischargePortId: PropTypes.number,
      }),
    }),
    handleClearFormData: PropTypes.func,
    formAttributes: PropTypes.shape({
      HAS_PACKER_AND_GRINDER: PropTypes.bool,
      HAS_VOYAGE_DETAILS: PropTypes.bool,
      IS_AMERICAN_DOMESTIC: PropTypes.bool,
      IS_AUSTRALIAN_DOMESTIC: PropTypes.bool,
      IS_GENERAL_DOMESTIC: PropTypes.bool,
      RECURRING_ENABLED: PropTypes.bool,
    }),
    onBuyIncoTermsChange: PropTypes.func,
    validateRequiredFields: PropTypes.func,
    validationErrors: PropTypes.shape({
      requiredFields: PropTypes.instanceOf(Object),
      dates: PropTypes.bool,
    }),
    requiredFieldsLabels: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func,
    currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    fecs: PropTypes.arrayOf(PropTypes.object).isRequired,
    grinders: PropTypes.arrayOf(PropTypes.object).isRequired,
    incoTerms: PropTypes.arrayOf(PropTypes.object).isRequired,
    inputProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadSizes: PropTypes.arrayOf(PropTypes.object),
    packerPlants: PropTypes.arrayOf(PropTypes.object).isRequired,
    packers: PropTypes.arrayOf(PropTypes.object).isRequired,
    ports: PropTypes.arrayOf(PropTypes.object).isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    token: PropTypes.string,
    unitsOfMeasure: PropTypes.arrayOf(PropTypes.object).isRequired,
    packageWeights: PropTypes.arrayOf(PropTypes.Object),
    configs: PropTypes.arrayOf(PropTypes.Object),
    handleChangeObjectList: PropTypes.func.isRequired,
    onFXRoundingDecimal: PropTypes.func.isRequired,
    handleChangeValue: PropTypes.func.isRequired,
    handleStateChangesCallback: PropTypes.func.isRequired,
    onFXChangeValue: PropTypes.func.isRequired,
    onBooleanValueChange: PropTypes.func.isRequired,
    onRecurringValueChange: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    confirmForm: PropTypes.func.isRequired,
    saveAndSend: PropTypes.func.isRequired,
    handleUpdatePurchaseOrder: PropTypes.func.isRequired,
    handleAddPurchaseOrder: PropTypes.func.isRequired,
    handleCopyPurchaseOrder: PropTypes.func.isRequired,
    handleRemovePurchaseOrder: PropTypes.func.isRequired,
    handleToggleChange: PropTypes.func.isRequired,
    state: {
      form: PropTypes.shape({
        buyCurrency: PropTypes.string,
        buyIncoterms: PropTypes.string,
        buyUnitOfMeasureId: PropTypes.n,
        fec: PropTypes.instanceOf(Object),
        halal: PropTypes.bool,
        mtc: PropTypes.bool,
        sellCurrency: PropTypes.string,
        sellIncoterms: PropTypes.string,
        sellUnitOfMeasureId: PropTypes.number,
      }),
      requiredFields: PropTypes.shape({
        form: PropTypes.arrayOf(PropTypes.string),
      }),
    },
  };

  handleChangeObjectList = (parentKey, listName, key, index, value, setString = false) => {
    this.props.handleChangeObjectList(parentKey, listName, key, index, value, setString);
  };

  clearFormData = () => {
    this.props.handleClearFormData();
  };

  onFXRoundingDecimal = (key, e) => {
    this.props.onFXRoundingDecimal(key, e);
  };

  onFXChangeValue = (key, e) => {
    this.props.onFXChangeValue(key, e);
  };

  handleStateChangesCallback(key, e) {
    this.props.handleStateChangesCallback(key, e);
  }

  handleChangeValue = (key, e) => {
    this.props.handleChangeValue(key, e);
  };

  onBooleanValueChange = (key, index) => {
    this.props.onBooleanValueChange(key, index);
  };

  onRecurringValueChange = (key, e, index) => {
    this.props.onRecurringValueChange(key, index);
  };

  closeModal = shouldClearForm => {
    this.props.closeModal(shouldClearForm);
  };

  confirmForm = send => {
    this.props.confirmForm(send);
  };

  saveAndSend = (form, submit) => {
    this.props.saveAndSend(form, submit);
  };

  handleUpdatePurchaseOrder = po => {
    this.props.handleUpdatePurchaseOrder(po);
  };

  handleAddPurchaseOrder = po => {
    this.props.handleUpdatePurchaseOrder(po);
  };

  handleCopyPurchaseOrder = purchaseOrderIdx => {
    this.props.handleCopyPurchaseOrder(purchaseOrderIdx);
  };

  handleRemovePurchaseOrder = purchaseOrderIdx => {
    this.props.handleRemovePurchaseOrder(purchaseOrderIdx);
  };

  handleToggleChange = fieldName => {
    this.props.handleToggleChange(fieldName);
  };

  render() {
    const {
      inputProducts,
      validationErrors,
      loadSizes,
      dispatch,
      formAttributes,
      ports,
      packerPlants,
      grinders,
      tags,
      baseForm,
      packageWeights,
      requiredFieldsLabels,
    } = this.props;
    const { form, confirmationOpened, isIneligible } = this.props.state;
    const { packerPlantId, packerId } = baseForm;

    const {
      purchaseOrderIndices,
      purchaseOrders,
      isChilled,
      isRecurring,
      buyUnitOfMeasureId,
      sellUnitOfMeasureId,
      buyCurrency,
      sellCurrency,
      isColdstoreRequired,
    } = form;
    const coldStoreFlagAvailable = typeof isColdstoreRequired !== 'undefined';
    const fullForm = {
      ...baseForm,
      ...form,
    };

    const { requiredFields } = validationErrors;

    const filteredInputProducts = filterInputProducts(inputProducts, form);

    return (
      <Box className="international-order-form" data-test="international-order-form">
        {confirmationOpened && (
          <InternationalConfirmation
            order={fullForm}
            closeModal={this.closeModal}
            opened={confirmationOpened}
            save={this.saveAndSend}
          />
        )}
        {/* <!-- RECURRING SECTION --> */}
        {baseForm.singleIngredientForm ? (
          false
        ) : (
          <RecurringSection
            requiredFields={requiredFields}
            onChange={this.handleChangeValue}
            onRecurringValueChange={this.onRecurringValueChange}
            form={form}
          />
        )}

        <VStack align="stretch" spacing="20px" marginTop="33px">
          <Flex justify="space-between">
            <Wrap spacing="29px">
              <CustomFormControl width="266px">
                <HStack spacing="15px">
                  <FormLabel margin={0} htmlFor="grinder-PO">
                    Product Type
                  </FormLabel>
                  <ProductTypeToggle
                    isDisabled={false}
                    isActive={isChilled}
                    onClick={() => this.handleToggleChange('isChilled')}
                  />
                </HStack>
              </CustomFormControl>
              {!isChilled && coldStoreFlagAvailable && (
                <CustomFormControl>
                  <HStack>
                    <FormLabel margin={0} htmlFor="isColdstoreRequired">
                      Coldstore
                    </FormLabel>
                    <Switch
                      size="lg"
                      colorScheme="actionPrimary"
                      id="isColdstoreRequired"
                      onChange={() => this.handleToggleChange('isColdstoreRequired')}
                      // Export loads default to true
                      defaultChecked={isColdstoreRequired}
                    />
                  </HStack>
                </CustomFormControl>
              )}
            </Wrap>
            {baseForm.singleIngredientForm &&
              (formAttributes.IS_AMERICAN_DOMESTIC ||
                formAttributes.IS_AUSTRALIAN_DOMESTIC ||
                formAttributes.IS_GENERAL_DOMESTIC) && (
                <CustomFormControl>
                  <HStack justifyContent="flex-end">
                    <FormLabel margin={0} htmlFor="isRecurring">
                      Recurring
                    </FormLabel>
                    <Switch
                      data-test="recurring-toggle"
                      id="isRecurring"
                      size="lg"
                      colorScheme="actionPrimary"
                      defaultChecked={isRecurring}
                      onChange={() => this.handleToggleChange('isRecurring')}
                    />
                  </HStack>
                </CustomFormControl>
              )}
          </Flex>
          {isIneligible ? (
            <>
              {/* <!-- SHOW ERROR FOR US DOMESTIC WITHOUT COLDSTORE --> */}
              <USDomesticError {...this.props} />
            </>
          ) : (
            <>
              {/* <!-- PURCHASE ORDER CARD --> */}
              {!isEmpty(purchaseOrderIndices) &&
                purchaseOrderIndices.map(idx => {
                  const po = purchaseOrders[idx];
                  const { grinderDestinationCountry, packerPlantOriginCountry } = this.props.state;
                  // These are required fields for <PurchaseOrderCard />
                  if (!grinderDestinationCountry || !packerPlantOriginCountry) {
                    return '';
                  }
                  return (
                    <PurchaseOrderCard
                      {...{
                        buyCurrency,
                        buyUnitOfMeasureId,
                        dispatch,
                        loadSizes,
                        grinderDestinationCountry,
                        idx: po.idx,
                        inputProducts: filteredInputProducts,
                        initialValues: po,
                        isChilled,
                        isColdstoreRequired,
                        isInternationalOrder:
                          !formAttributes.IS_AMERICAN_DOMESTIC &&
                          !formAttributes.IS_AUSTRALIAN_DOMESTIC &&
                          !formAttributes.IS_GENERAL_DOMESTIC,
                        isInEditMode: true,
                        isRecurring: form.isRecurring,
                        key: idx,
                        onAddPurchaseOrder: this.handleAddPurchaseOrder,
                        onRemovePurchaseOrder: this.handleRemovePurchaseOrder,
                        onUpdatePurchaseOrder: this.handleUpdatePurchaseOrder,
                        onCopyPurchaseOrder: this.handleCopyPurchaseOrder,
                        packerPlantOriginCountry,
                        packerId,
                        packerPlantId,
                        purchasingOffice: baseForm.purchasingOffice,
                        purchaseOrderIndices,
                        validationErrors: requiredFields,
                        sellCurrency,
                        sellUnitOfMeasureId,
                        ports,
                        form: fullForm,
                        packerPlants,
                        grinders,
                        tags,
                        packageWeights,
                        requiredFieldsLabels,
                      }}
                    />
                  );
                })}
            </>
          )}
        </VStack>
      </Box>
    );
  }
}

export default withInternationalOrderFormContext(InternationalOrderForm);
