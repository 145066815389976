import React from 'react';
import { Box, Text, UnorderedList, ListItem, Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Other = ({ data }) => {
  return (
    <Box marginBottom="15px">
      <Text color="rgba(0, 0, 0, 0.87)" fontSize="14px" fontWeight="600" lineHeight="1.43" marginBottom="15px">
        Other
      </Text>
      {data && data?.length > 0 && (
        <UnorderedList fontSize="14px" marginBottom="10px" color="#000000B2" style={{ columnCount: 2 }}>
          {data?.map(otherlink => {
            return (
              <ListItem marginBottom="10px" paddingRight="10px">
                <Link
                  href={otherlink?.filepath}
                  marginLeft="4px"
                  color="#3A44B0"
                  textDecoration="underline"
                  _hover={{
                    color: '#3A44B0',
                    textDecoration: 'underline',
                  }}
                  target="_blank"
                >
                  {otherlink?.title}
                </Link>
              </ListItem>
            );
          })}
        </UnorderedList>
      )}
    </Box>
  );
};

Other.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      filepath: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default Other;
