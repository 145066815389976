import { Tabs, TabList, TabPanels, TabPanel, Box, Button, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SelectColumn } from 'react-data-grid';

import {
  createPackerInvoiceDrawDownReports,
  fetchPackerInvoicedPurchaseOrders,
  fetchPackerInvoicePurchaseOrders,
} from '../../../actions/actions_invoicing';
import { downloadFromS3Link } from '../../../helpers/downloads';
import Loading from '../../basic/Loading';
import Constants from '../../../Constants';
import { FinanceNavMainTabs, ResultTab } from '../FinanceNavMainTabs';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import DrawDownFilters from './DrawDownFilters/DrawDownFilters';
import DrawDownTable from './DrawDownTable/DrawDownTable';
import { fetchConfigs } from '../../../actions/actions_config';
import { getInternalPoLabel } from '../../../utils';

const TABS = {
  generateReport: 'Generate Report',
  generatedReports: 'Generated Reports',
};

const TABS_LIST = [TABS.generateReport, TABS.generatedReports];

const DEFAULT_ACTIVE_TAB = TABS.generateReport;

class DrawDownReports extends Component {
  static propTypes = {
    token: PropTypes.string,
    dispatch: PropTypes.func,
    drawDownHistoricalData: PropTypes.shape({
      orders: PropTypes.arrayOf(PropTypes.object),
      pagination: PropTypes.shape({
        pages: PropTypes.number,
        has_next: PropTypes.bool,
        has_previous: PropTypes.bool,
        next_page: PropTypes.number,
        previous_page: PropTypes.number,
        total: PropTypes.number,
      }),
      pageCount: PropTypes.number,
    }),
    ordersToDrawDown: PropTypes.arrayOf(PropTypes.object),
    isSearching: PropTypes.bool,
    configs: PropTypes.arrayOf(PropTypes.object),
  };

  state = {
    selectedTab: DEFAULT_ACTIVE_TAB,
    isNotInPreviousReports: true,
    readyForPaymentStartDate: '',
    readyForPaymentEndDate: '',
    invoicingOffice: Constants.BUSINESS_OFFICES.CHICAGO.value,
    generationColumnList: [
      SelectColumn,
      {
        key: 'internal_po_number',
        name: 'internal_po_number',
      },
      {
        key: 'grinder_po_number',
        name: 'Grinder PO #',
      },
      {
        key: 'packer_plant_name',
        name: 'Establishment',
      },
      {
        key: 'packer_invoice_number',
        name: 'Packer Invoice #',
      },
      {
        key: 'ready_for_payment_checked_at',
        name: 'Ready For Payment On',
        formatFunc: 'formatDate',
      },
      {
        key: 'invoicing_office',
        name: 'Invoicing Office',
      },
      {
        key: 'draw_down_report_created_at',
        name: 'Drawdown',
        formatFunc: 'formatDate',
      },
    ],
    selectedRowsList: [],
    isLoading: false,
    generatedColumnList: [
      {
        key: 'created',
        name: 'Created On',
        formatFunc: 'formatDate',
      },
      {
        name: 'Date from',
        key: 'ready_for_payment_start_date',
      },
      {
        name: 'Date to',
        key: 'ready_for_payment_end_date',
      },
      {
        key: 'invoicing_office',
        name: 'Invoicing Office',
      },
      {
        key: 'user_name',
        name: 'Created By',
      },
      {
        key: 'download_url',
        name: 'Report',
        formatFunc: 'download',
      },
    ],
    pageNum: 1,
  };

  componentDidMount() {
    const { token, dispatch, configs } = this.props;
    dispatch(
      fetchPackerInvoicedPurchaseOrders(token, {
        pageNum: this.state.pageNum,
      })
    );
    if (configs.length === 0) {
      dispatch(fetchConfigs(token));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { token, dispatch, generatedDrawDownLink } = this.props;

    if (generatedDrawDownLink && generatedDrawDownLink !== prevProps.generatedDrawDownLink) {
      downloadFromS3Link(generatedDrawDownLink.url);
      this.setState({ isLoading: false });
      // if new reports generated, update generated section
      dispatch(
        fetchPackerInvoicedPurchaseOrders(token, {
          pageNum: 1,
        })
      );
    }
    // when page changes
    if (prevState.pageNum !== this.state.pageNum) {
      dispatch(
        fetchPackerInvoicedPurchaseOrders(token, {
          pageNum: this.state.pageNum,
        })
      );
    }
  }

  handleTabChange = selectedTab => {
    const tabName = TABS_LIST[selectedTab];
    this.setState({
      selectedTab: tabName,
    });
  };

  onChangeFilterValue = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handlePageChange = ({ selected }) => {
    const pageNum = selected + 1;
    this.setState({ pageNum });
  };

  fetchToDrawDownOrders = () => {
    const { isNotInPreviousReports, readyForPaymentStartDate, readyForPaymentEndDate, invoicingOffice } = this.state;
    const { token, dispatch } = this.props;
    dispatch(
      fetchPackerInvoicePurchaseOrders(token, {
        isNotInPreviousReports,
        readyForPaymentStartDate,
        readyForPaymentEndDate,
        invoicingOffice,
      })
    );
  };

  handleRowSelect = selectedOrdersSet => {
    const { ordersToDrawDown } = this.props;

    const selectedRowsList = ordersToDrawDown.filter(row =>
      [...selectedOrdersSet].includes(`${row.internal_po_number}${row.grinder_po_number}`)
    );
    this.setState({
      selectedRowsList,
    });
  };

  handleSubmit = () => {
    const { token, dispatch } = this.props;
    const { readyForPaymentStartDate, readyForPaymentEndDate, invoicingOffice } = this.state;
    const { selectedRowsList } = this.state;

    const grinderPOs = selectedRowsList.map(row => row.grinder_po_number).filter(g => g !== null || g !== "");
    const fmgPOs = selectedRowsList.map(row => row.internal_po_number).filter(f => f !== null);

    const filters = {
      readyForPaymentStartDate,
      readyForPaymentEndDate,
      invoicingOffice,
    };

    this.setState({ isLoading: true }, () => {
      dispatch(
        createPackerInvoiceDrawDownReports(token, {
          grinderPOs,
          filters,
          fmgPOs,
        })
      );
    });
  };

  setInternalPoLabel = label => {
    if (!label) {
      return null;
    }
    if (
      this.state.generationColumnList[1].name !== 'internal_po_number' ||
      this.state.generationColumnList[1].name === label
    ) {
      return null;
    }
    const copy = this.state.generationColumnList;
    copy[1].name = label;
    this.setState({ generationColumnList: copy });
  };

  render() {
    const { selectedTab, selectedRowsList, generationColumnList, generatedColumnList, isLoading } = this.state;
    const { ordersToDrawDown, drawDownHistoricalData, isSearching, configs } = this.props;
    const internal_po_label = getInternalPoLabel(configs);
    this.setInternalPoLabel(internal_po_label);
    return (
      <VStack spacing="56px" align="stretch" marginBottom="95px">
        <FinanceNavMainTabs activeTabIndex={1} />
        <VStack align="stretch" paddingX="51px" spacing="37px">
          <Tabs defaultActiveKey={TABS.results} activeKey={selectedTab} onChange={this.handleTabChange}>
            <TabList width="max-content" borderBottomWidth="0px">
              <ResultTab key={TABS.generateReport} title="Generate Report" />
              <ResultTab key={TABS.generatedReports} title="Generated Reports" />
            </TabList>
            <TabPanels>
              <TabPanel tab="Generate Report" key={TABS.generateReport} padding="0px">
                <VStack spacing="37px" align="stretch">
                  <DashboardSectionTile
                    borderTopLeftRadius="0px"
                    title="1. Filter Pos to Generate Report From"
                    minWidth="1266px"
                  >
                    {/* Filters to get POs required to generate draw down rpeorts */}
                    <DrawDownFilters
                      isSearching={isSearching}
                      onChangeFilter={this.onChangeFilterValue}
                      {...this.state}
                      onSearch={this.fetchToDrawDownOrders}
                    />
                  </DashboardSectionTile>

                  {ordersToDrawDown.length && (
                    <DashboardSectionTile title="2. Select Pos for Drawdown Report Generation" minWidth="1266px">
                      {/* Fetched POs for filters used */}
                      <Box key="finance-page-drawdown-generate__grid-results">
                        <Box>
                          <DrawDownTable
                            ordersToDrawDown={ordersToDrawDown}
                            selectedRows={selectedRowsList}
                            columnList={generationColumnList}
                            onRowSelect={this.handleRowSelect}
                            tableInfo="Choose which items to generate a report from"
                            displayTotalsText
                          />
                        </Box>
                      </Box>
                    </DashboardSectionTile>
                  )}

                  <Box marginLeft="auto !important">
                    {isLoading || isSearching ? (
                      <Loading style={{ width: 'max-content', height: 'fit-content', padding: '0px' }} />
                    ) : (
                      <Button
                        onClick={this.handleSubmit}
                        isDisabled={!ordersToDrawDown.length}
                        colorScheme="actionPrimary"
                        width="278px"
                        height="40px"
                        fontWeight="black"
                        fontSize="16px"
                      >
                        Generate Drawdown Report(s)
                      </Button>
                    )}
                  </Box>
                </VStack>
              </TabPanel>
              {/* Generated reports  */}
              <TabPanel tab="Generated Reports" key={TABS.generatedReports} padding="0px">
                <DashboardSectionTile borderTopLeftRadius="0px" minWidth="1266px">
                  {drawDownHistoricalData && (
                    <DrawDownTable
                      ordersToDrawDown={drawDownHistoricalData.orders}
                      columnList={generatedColumnList}
                      pagination={drawDownHistoricalData.pagination}
                      onPageChange={this.handlePageChange}
                      displayTotalsText={false}
                    />
                  )}
                </DashboardSectionTile>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </VStack>
    );
  }
}

export default DrawDownReports;
