import { isEmpty, isNil, isNull } from 'lodash';
import Constants from '../Constants';
import axios from 'axios';
import { getOr } from '../helpers';
import qs from 'query-string';
import { loadOrderHistory } from './actions_orders';

export const LOAD_NCRS = 'LOAD_NCRS';
export const LOAD_REVIEW_NCR = 'LOAD_REVIEW_NCR';
export const RESET_REVIEW_NCR = 'RESET_REVIEW_NCR';
export const SEARCH_NCRS_START = 'SEARCH_NCRS_START';
export const SEARCH_NCRS_FINISH = 'SEARCH_NCRS_FINISH';
export const SEARCH_NCRS_ERROR = 'SEARCH_NCRS_ERROR';
export const SEARCH_NCRS_RESET = 'SEARCH_NCRS_RESET';
export const SEARCH_NCRS_REFRESH = 'SEARCH_NCRS_REFRESH';
export const LOAD_NCR_ORDER_START = 'LOAD_NCR_ORDER_START';
export const LOAD_NCR_ORDER_ERROR = 'LOAD_NCR_ORDER_ERROR';
export const NCR_CREATION_START = 'NCR_CREATION_START';
export const NCR_CREATION_FINISH = 'NCR_CREATION_FINISH';
export const NCR_CREATION_ERROR = 'NCR_CREATION_ERROR';
export const NCR_CREATION_RESET = 'NCR_CREATION_RESET';
export const NCR_ATTACHMENT_UPLOAD_START = 'NCR_ATTACHMENT_UPLOAD_START';
export const NCR_ATTACHMENT_UPLOAD_FINISH = 'NCR_ATTACHMENT_UPLOAD_FINISH';
export const NCR_ATTACHMENT_UPLOAD_SAVE = 'NCR_ATTACHMENT_UPLOAD_SAVE';
export const NCR_ATTACHMENT_UPLOAD_SET = 'NCR_ATTACHMENT_UPLOAD_SET';
export const NCR_ATTACHMENT_UPLOAD_ERROR = 'NCR_ATTACHMENT_UPLOAD_ERROR';
export const NCR_ATTACHMENT_UPLOAD_RESET = 'NCR_ATTACHMENT_UPLOAD_RESET';
export const NCR_EDIT_START = 'NCR_EDIT_START';
export const NCR_EDIT_FINISH = 'NCR_EDIT_FINISH';
export const NCR_EDIT_ERROR = 'NCR_EDIT_ERROR';
export const NCR_EDIT_RESET = 'NCR_EDIT_RESET';
export const NCR_DELETE_START = 'NCR_DELETE_START';
export const NCR_DELETE_FINISH = 'NCR_DELETE_FINISH';
export const NCR_DELETE_ERROR = 'NCR_DELETE_ERROR';
export const NCR_DELETE_RESET = 'NCR_DELETE_RESET';
export const NCR_ORDER_ERROR = 'NCR_ORDER_ERROR';
export const CLEAR_NCR_ORDER_ERROR = 'CLEAR_NCR_ORDER_ERROR';
export const LOAD_NCR_SUMMARY = 'LOAD_NCR_SUMMARY';
export const CLEAR_CHANGE_INCIDENT = 'CLEAR_CHANGE_INCIDENT';
export const NCR_RELEASE_START = 'NCR_RELEASE_START';
export const NCR_RELEASE_FINISH = 'NCR_RELEASE_FINISH';
export const NCR_RELEASE_ERROR = 'NCR_RELEASE_ERROR';
export const NCR_RELEASE_RESET = 'NCR_RELEASE_RESET';
export const INCIDENT_BY_GROUP_ID_START = 'INCIDENT_BY_GROUP_ID_START';
export const INCIDENT_BY_GROUP_ID_FINISH = 'INCIDENT_BY_GROUP_ID_FINISH';
export const INCIDENT_BY_GROUP_ID_ERROR = 'INCIDENT_BY_GROUP_ID_ERROR';
export const CHANGE_TO_NCR_START = 'CHANGE_TO_NCR_START';
export const CHANGE_TO_NCR_FINISH = 'CHANGE_TO_NCR_FINISH';
export const CHANGE_TO_NCR_ERROR = 'CHANGE_TO_NCR_ERROR';
export const LOAD_REVIEW_INCIDENT = 'LOAD_REVIEW_INCIDENT';
export const RESET_REVIEW_INCIDENT = 'RESET_REVIEW_INCIDENT';
export const ERROR_REVIEW_INCIDENT = 'ERROR_REVIEW_INCIDENT';
export const START_NCR_PDF = 'START_NCR_PDF'
export const FINISH_NCR_PDF = 'FINISH_NCR_PDF'
export const ERROR_NCR_PDF = 'ERROR_NCR_PDF'
export const START_INCIDENT_PDF = 'START_INCIDENT_PDF';
export const FINISH_INCIDENT_PDF = 'FINISH_INCIDENT_PDF';
export const ERROR_INCIDENT_PDF = 'ERROR_INCIDENT_PDF';
export const LOAD_REVIEW_INCIDENTS = 'LOAD_REVIEW_INCIDENTS'

export function startNCRCreation() {
  return {
    type: NCR_CREATION_START,
  };
}

export function resetNCRCreation() {
  return {
    type: NCR_CREATION_RESET,
  };
}

export function finishNCRCreation() {
  return {
    type: NCR_CREATION_FINISH,
  };
}

export function createNCRError(error) {
  return {
    type: NCR_CREATION_ERROR,
    payload: error,
  };
}

export function startNCREdit() {
  return {
    type: NCR_EDIT_START,
  };
}

export function resetNCREdit() {
  return {
    type: NCR_EDIT_RESET,
  };
}

export function finishNCREdit() {
  return {
    type: NCR_EDIT_FINISH,
  };
}

export function editNCRError(error) {
  return {
    type: NCR_EDIT_ERROR,
    payload: error,
  };
}

export function loadNCRs(order) {
  return {
    type: LOAD_NCRS,
    payload: order,
  };
}

export function startNCRSearch() {
  return {
    type: SEARCH_NCRS_START,
  };
}

export function finishNCRSearch() {
  return {
    type: SEARCH_NCRS_FINISH,
  };
}

export function resetNCRSearch() {
  return {
    type: SEARCH_NCRS_RESET,
  };
}

export function searchNCRError(error) {
  return {
    type: SEARCH_NCRS_ERROR,
    payload: error,
  };
}

export function loadReviewNCR(ncr) {
  return {
    type: LOAD_REVIEW_NCR,
    payload: ncr,
  };
}

export function loadReviewIncidetns(incidents){
  return {
    type: LOAD_REVIEW_INCIDENTS,
    payload: incidents,
  };  
}

export function resetReviewNCR() {
  return {
    type: RESET_REVIEW_NCR,
  };
}

export function startNCRAttachmentUpload() {
  return {
    type: NCR_ATTACHMENT_UPLOAD_START,
  };
}

export function finishNCRAttachmentUpload() {
  return {
    type: NCR_ATTACHMENT_UPLOAD_FINISH,
  };
}

export function saveNCRAttachmentUpload(attachment) {
  return {
    type: NCR_ATTACHMENT_UPLOAD_SAVE,
    payload: attachment,
  };
}

export function setNCRAttachmentsUpload(attachments) {
  return {
    type: NCR_ATTACHMENT_UPLOAD_SET,
    payload: attachments,
  };
}

export function resetNCRAttachmentUpload() {
  return {
    type: NCR_ATTACHMENT_UPLOAD_RESET,
  };
}

export function uploadNCRAttachmentError(error) {
  return {
    type: NCR_ATTACHMENT_UPLOAD_ERROR,
    payload: error,
  };
}

export function startNCRDelete() {
  return {
    type: NCR_DELETE_START,
  };
}

export function finishNCRDelete() {
  return {
    type: NCR_DELETE_FINISH,
  };
}

export function deleteNCRError(error) {
  return {
    type: NCR_DELETE_ERROR,
    payload: error,
  };
}

export function resetNCRDelete() {
  return {
    type: NCR_DELETE_RESET,
  };
}

export function startNCRRelease() {
  return {
    type: NCR_RELEASE_START,
  };
}

export function finishNCRRlease() {
  return {
    type: NCR_RELEASE_FINISH,
  };
}

export function releaseNCRError(error) {
  return {
    type: NCR_RELEASE_ERROR,
    payload: error,
  };
}

export function resetNCRRelease() {
  return {
    type: NCR_RELEASE_RESET,
  };
}

export function loadNCRSummary(payload) {
  return {
    type: LOAD_NCR_SUMMARY,
    payload,
  };
}

export function clearChangeIncident(){
  return {
    type: CLEAR_CHANGE_INCIDENT
  }
}

export function errorNCROrder(error) {
  return {
    type: NCR_ORDER_ERROR,
    payload: error,
  };
}

export function clearNCRErrorOrder() {
  return {
    type: CLEAR_NCR_ORDER_ERROR,
  };
}


export function startIncidentsByGroupId() {
  return {
    type: INCIDENT_BY_GROUP_ID_START,
  };
}

export function finishIncidentsByGroupId(payload) {
  return {
    type: INCIDENT_BY_GROUP_ID_FINISH,
    payload
  };
}

export function errorIncidentsByGroupId(error) {
  return {
    type: INCIDENT_BY_GROUP_ID_ERROR,
    payload: error,
  };
}

export function startChangeToNCR() {
  return {
    type: CHANGE_TO_NCR_START,
  };
}

export function finishChangeToNCR(payload) {
  return {
    type: CHANGE_TO_NCR_FINISH,
    payload
  };
}

export function errorChangeToNCR(error) {
  return {
    type: CHANGE_TO_NCR_ERROR,
    payload: error,
  };
}

export function loadReviewINCIDENT(incident) {
  return {
    type: LOAD_REVIEW_INCIDENT,
    payload: incident,
  };
}

export function resetReviewINCIDENT() {
  return {
    type: LOAD_REVIEW_INCIDENT,
  };
}

export function errorReviewINCIDENT() {
  return {
    type: ERROR_REVIEW_INCIDENT,
  };
}

export function startNCRPDFDownload() {
  return {
    type: START_NCR_PDF,
  }
}

export function finishNCRPDFDownload(){
  return {
    type: FINISH_NCR_PDF,
  }
}

export function errorNCRPDFDownload(){
  return {
    type: ERROR_NCR_PDF,
  }
}

export function startIncidentPDFDownload() {
  return {
    type: START_INCIDENT_PDF,
  }
}

export function finishIncidentPDFDownload(){
  return {
    type: FINISH_INCIDENT_PDF,
  }
}

export function errorIncidentPDFDownload(){
  return {
    type: ERROR_INCIDENT_PDF
  }
}

export function createGrinderNCR(payload, token) {
  return async dispatch => {
    dispatch(startNCRCreation());
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr`, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(payload),
      });
      const ncrResult = await Constants.handleErrors(response, dispatch, createNCRError);
      dispatch(finishNCRCreation());
      dispatch(loadReviewNCR(Array.isArray(ncrResult) ? ncrResult[0] : ncrResult));
      dispatch(loadReviewIncidetns(ncrResult))
    } catch (error) {
      dispatch(createNCRError(error));
    }
    return null;
  };
}

export function updateGrinderNCR(payload, token, changeReqFor,ncrType=null) {
  return async dispatch => {
    dispatch(startNCREdit());
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      'ChangeReqFor': changeReqFor,
    });

    const url = ncrType &&  ncrType === Constants.NCR_TYPES.INCIDENT ? `${Constants.URL}incidents/${payload.record_no}` : `${Constants.URL}grinder_ncr/${payload.record_no}`
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: header,
        body: JSON.stringify(payload),
      });
      const ncrResult = await Constants.handleErrors(response, dispatch, editNCRError);
      dispatch(finishNCREdit());
      if (!isEmpty(ncrResult)) dispatch(loadReviewNCR(ncrResult));
    } catch (error) {
      dispatch(editNCRError(error));
    }
    return null;
  };
}

export function updateGrinderOnlyReleaseToPacker(payload, token) {
  return async dispatch => {
    dispatch(startNCRRelease);
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr/${payload.record_no}`, {
        method: 'PATCH',
        headers: header,
        body: JSON.stringify(payload),
      });
      const ncrResult = await Constants.handleErrors(response, dispatch, releaseNCRError);
      dispatch(finishNCRRlease());
    } catch (error) {
      dispatch(releaseNCRError(error));
    }
    return null;
  };
}

export function searchGrinderNCRs(parameters, token) {
  return async dispatch => {
    try {
      dispatch(startNCRSearch());
      const header = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      });

      const cleanParams = Object.entries(parameters).reduce((agg, [key, value]) => {
        if (isNil(value) || value === '') {
          return agg;
        }
        let param = value;
        if (Array.isArray(value)) {
          param = value.map(option => getOr(option, 'value', option));
        }
        if (typeof value === 'string' && value.indexOf(',') > -1) {
          param = value.split(',');
        }

        return {
          ...agg,
          [key]: param,
        };
      }, {});

      const queryString = qs.stringify(cleanParams, { arrayFormat: 'comma' });

      const response = await fetch(`${Constants.URL}grinder_ncr?${queryString}`, {
        method: 'GET',
        headers: header,
      });
      const result = await Constants.handleErrors(response, dispatch, searchNCRError);
      dispatch(finishNCRSearch());
      let ncrsResult;
      if (result) {
        ncrsResult = {
          ...result,
        };
        const items = [...result.items];
        items.sort((x, y) => {
          let sortVerdict;
          const a = getOr(x, 'record_no', null);
          const b = getOr(y, 'record_no', null);
          if (a && b && !isNaN(parseFloat(a)) && !isNaN(parseFloat(b))) {
            sortVerdict = parseFloat(b) - parseFloat(a);
          } else if (a && b && typeof a === 'string' && typeof b === 'string') {
            sortVerdict = b.localeCompare(a);
          } else if (isNull(a)) sortVerdict = 1;
          else if (isNull(b)) sortVerdict = -1;
          else if (isEmpty(a)) sortVerdict = 1;
          else if (isEmpty(b)) sortVerdict = -1;
          else sortVerdict = b - a;
          return sortVerdict;
        });
        ncrsResult.items = items;
      }
      dispatch(loadNCRs(ncrsResult));
    } catch (error) {
      dispatch(searchNCRError(error));
    }
    return null;
  };
}

export function fetchGrinderNCR(recordNo, token, ncrType=null) {
  return async dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    const url = ncrType &&  ncrType === Constants.NCR_TYPES.INCIDENT ? `${Constants.URL}incidents/${recordNo}` : `${Constants.URL}grinder_ncr/${recordNo}`

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: header,
      });
      const ncrResult = await Constants.handleErrors(response, dispatch, searchNCRError);
      dispatch(finishNCRSearch());
      dispatch(loadReviewNCR(ncrResult));
    } catch (error) {
      dispatch(searchNCRError(error));
    }
    return null;
  };
}

export function uploadAttachments(fileList) {
  return async dispatch => {
    dispatch(startNCRAttachmentUpload());
    if (fileList.length < 1) {
      dispatch(setNCRAttachmentsUpload([]));
      dispatch(finishNCRAttachmentUpload());
      return null;
    }
    const uploadReqs = [];
    for (let index = 0; index < fileList.length; index += 1) {
      const fileData = fileList[index];
      if (fileData.file) {
        if (!fileData.url.includes('https://s3.amazonaws.com')){
          const formData = new FormData();
          formData.append('file', fileData.file);
          formData.append('file_name', fileData.file.name);
          uploadReqs.push(axios.post(`${Constants.URL}file-upload`, formData));          
        } else {
          uploadReqs.push({data: fileData.url});
        }
      } else {
        uploadReqs.push(
          new Promise(resolve => {
            resolve({
              id: fileData.id,
              data: fileData.url,
            });
          })
        );
      }
    }
    Promise.all(uploadReqs)
      .then(responses => {
        responses.forEach((response, index) => {
          dispatch(
            saveNCRAttachmentUpload({
              ...(response.id && { id: response.id }),
              url: response.data,
              type: fileList[index].type,
            })
          );
        });
        dispatch(finishNCRAttachmentUpload());
      })
      .catch(error => {
        Constants.handleErrors(error, dispatch, uploadNCRAttachmentError);
      })
      .catch(error => {
        dispatch(uploadNCRAttachmentError(error));
      });

    return null;
  };
}

export function deleteNCR(payload, token) {
  return async dispatch => {
    dispatch(startNCRDelete());
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr/${payload.recordNo}`, {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(payload),
      });
      await Constants.handleErrors(response, dispatch, deleteNCRError);
      dispatch(finishNCRDelete());
    } catch (error) {
      dispatch(deleteNCRError(error));
    }
    return null;
  };
}

export function fetchNCROrder(token, packerIdInternalPOs) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    let appendUrl = ''
    for(let po of packerIdInternalPOs){
      if (appendUrl !== ''){
          appendUrl = appendUrl + '&'
      }
      appendUrl =  appendUrl + `grinder_po_number=${po.internalPO}`
      appendUrl = appendUrl + `&packer_ids=${po.packerId}`
      if(po.packerPlantId) {
        appendUrl = appendUrl + `&packer_plant_id=${po.packerPlantId}`
      }
    }
    return fetch(`${Constants.URL}ncr_purchase_order?${appendUrl}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorNCROrder))
      .then(json => {
        dispatch(loadOrderHistory(json))
      })
      .catch(error => {
        dispatch(errorNCROrder(error));
      });
  };
}

export function fetchNCRSummary(token) {
  return dispatch => {
    const header = new Headers({ Authorization: `Token ${token}` });
    return fetch(`${Constants.URL}ncr_summary`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorNCROrder))
      .then(json => dispatch(loadNCRSummary(json)))
      .catch(error => console.log(error));
  };
}

export function fetchIncidentsByGroupId(token, groupId){
  return async dispatch => {
    dispatch(startIncidentsByGroupId())
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    
    try {
      const response = await fetch(`${Constants.URL}incidents/?group_id=${groupId}`, {
        method: 'GET',
        headers: header,
      })
      const incidentsGroupById = await Constants.handleErrors(response, dispatch, errorIncidentsByGroupId)
      dispatch(finishIncidentsByGroupId(incidentsGroupById))
    } catch (error) {
      dispatch(errorIncidentsByGroupId(error))
      console.log(error)
    }
    return null
  };
}

export function fetchChangeIncident(token, recordNo){
  return async dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}incidents/${recordNo}`, {
        method: 'GET',
        headers: header,
      })
      const incident = await Constants.handleErrors(response, dispatch, errorReviewINCIDENT)
      if (!isEmpty(incident)) dispatch(loadReviewINCIDENT(incident));
    } catch (error) {
      dispatch(errorReviewINCIDENT)
      console.log(error)
    }
  }
}

export function resetChangeIncident(){
  return async dispatch => {
    dispatch(clearChangeIncident())
  }
}

export function downloadNCRPDF(token, recordNo){
  return async dispatch => {
    dispatch(startNCRPDFDownload());
    const header = new Headers({
      'Content-Type': 'application/pdf',
      Authorization: `Token ${token}`,
      responseType: 'blob',
    });

    try {
      await fetch(`${Constants.URL}ncr/${recordNo}/pdf`, {
        method: 'GET',
        headers: header,
      }).then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `NCR-${recordNo}.pdf`;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);        
      })
      .catch(error => console.error('Error downloading PDF:', error));
    } catch (error) {
      console.log(error)
    }
  }

}

export function downloadIncidentPDF(token, recordNo){
  return async dispatch => {
    dispatch(startIncidentPDFDownload());
    const header = new Headers({
      'Content-Type': 'application/pdf',
      Authorization: `Token ${token}`,
      responseType: 'blob',
    });

    try {
      await fetch(`${Constants.URL}incidents/${recordNo}/pdf`, {
        method: 'GET',
        headers: header,
      }).then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `Incident-${recordNo}.pdf`;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);        
      })
      .catch(error => console.error('Error downloading PDF:', error));
    } catch (error) {
      console.log(error)
    }
  }

}
