import React from 'react';
import PropTypes from 'prop-types';
import { Heading, HStack, Tag, Text } from '@chakra-ui/react';
import { ReactComponent as PorkIconSVG } from '../../assets/pork-icon.svg';
import { ReactComponent as BeefIconSVG } from '../../assets/beef-icon.svg';

const PurchaseOrderInfo = ({ isFrozenProduct, grinderIsUnfunded, productType }) => {
  return (
    <HStack spacing="21px">
      <Heading fontSize="24px !important">US {isFrozenProduct ? 'Frozen' : 'Fresh'} Domestic</Heading>
      <HStack spacing="14px">
        {isFrozenProduct ? (
          <Tag size="lg" fontSize="16px" colorScheme="actionNeutral">
            Coldstore
          </Tag>
        ) : (
          <Tag size="lg" fontSize="16px" colorScheme="actionNeutral">
            {grinderIsUnfunded ? 'Unfunded' : 'Funded'}
          </Tag>
        )}
        <Tag size="lg" backgroundColor="#979797" color="#FFFFFF">
          <Text fontSize="16px" textTransform="capitalize">
            {productType.toLowerCase()}
          </Text>
        </Tag>
        {productType.toLowerCase() === 'beef' ? <BeefIconSVG /> : <PorkIconSVG />}
      </HStack>
    </HStack>
  );
};

PurchaseOrderInfo.propTypes = {
  isFrozenProduct: PropTypes.bool,
  grinderIsUnfunded: PropTypes.bool,
  productType: PropTypes.string,
};

export default PurchaseOrderInfo;
