import React from 'react';
import { HStack, Tab, TabList, Tabs, Text, Wrap } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import { ChevronRightIcon } from '@chakra-ui/icons';

const tabsConfig = [
  { url: '/finance-invoicing', component: 'FinancePageComponent', title: 'Invoicing' },
  {
    url: '/finance-invoicing/drawdown-reports',
    component: 'DrawDownReportsComponent',
    title: 'Drawdown Reports',
  },
];
export const FinanceNavMainTabs = ({ activeTabIndex, ...rest }) => {
  return (
    <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
      <Wrap spacing="20px" width="100%">
        <HStack>
          <Text as="p" fontWeight="700">
            Finance
          </Text>
          <ChevronRightIcon width="25px" height="25px" />
        </HStack>
        <Tabs index={activeTabIndex} {...rest}>
          <TabList borderBottomWidth="0px">
            {tabsConfig.map(tab => (
              <Link to={tab.url} key={tab.title} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Tab
                  _focus={{ outline: 'none' }}
                  _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                  _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                  fontWeight="normal"
                  width="162px"
                  fontSize="14px"
                  padding="0px"
                  height="103px"
                  marginRight="10px"
                >
                  {tab.title}
                </Tab>
              </Link>
            ))}
          </TabList>
        </Tabs>
      </Wrap>
    </HorizontalNavigationBand>
  );
};

FinanceNavMainTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
};

export const ResultTab = ({ key, title, ...rest }) => {
  return (
    <Tab
      key={key}
      _focus={{ outline: 'none' }}
      borderTopRadius="6px"
      borderWidth="1px"
      borderColor="neutral.9"
      marginBottom="0px"
      _selected={{
        borderWidth: '0px',
        boxShadow: '-2px -1px 2px 0px var(--chakra-colors-gray-100)',
        fontWeight: 'bold',
      }}
      backgroundColor="card.default"
      marginRight="5px"
      {...rest}
    >
      {title}
    </Tab>
  );
};

ResultTab.propTypes = {
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
