import React, { useCallback, useEffect, useState } from 'react';
import {
  HStack,
  Select,
  InputGroup,
  Input,
  IconButton,
  Flex,
  InputLeftElement,
  VStack,
  Heading,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { RiPencilFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import NoData from '../_components/NoData';
import Tooltip from '../_components/Tooltip';
import AddDocument from '../_components/AddDocument';
import ViewDocument from '../_components/ViewDocument';
import { PaginationBar } from '../../core/PaginationComponents';
import RightAngleIcon from '../../core/RightAngleIcon';
import { fetchResources } from '../../../reducers/reducer_food_safety_quality';
import DownloadResource from '../_components/DownloadResource';
import { getTenantFromDomain } from '../../../helpers/tenant';

const fmgColumns = [
  {
    title: 'TITLE',
    dataIndex: 'rs_title',
    key: 'title',
  },
  {
    title: 'DOCUMENT NO.',
    dataIndex: 'rs_doc_number',
    key: 'rs_doc_number',
    sorter: (a, b) => {
      const resourceA = a.rs_doc_number.toUpperCase();
      const resourceB = b.rs_doc_number.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'ISSUED DATE',
    dataIndex: 'rs_issued_date',
    key: 'issued_date',
    sorter: (a, b) => {
      return moment(a.rs_issued_date).unix() - moment(b.rs_issued_date).unix();
    },
    render: text => (text ? moment(text).format('DD MMM YYYY') : '-'),
  },
  {
    title: 'LAST UPDATED',
    dataIndex: 'rs_last_updated',
    key: 'last_updated',
    sorter: (a, b) => {
      return moment(a.rs_last_updated).unix() - moment(b.rs_last_updated).unix();
    },
    render: text => (text ? moment(text).format('DD MMM YYYY') : '-'),
  },
  {
    title: 'UPLOADED BY',
    dataIndex: 'uploaded_by',
    key: 'uploaded_by',
    sorter: (a, b) => {
      const resourceA = a.uploaded_by.toUpperCase();
      const resourceB = b.uploaded_by.toUpperCase();
      if (resourceA < resourceB) {
        return -1;
      }
      if (resourceA > resourceB) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: 'ACTION',
    dataIndex: 'actions',
    key: 'actions',
  },
];

/**
 * Renders the FmgDocs component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpenAddPopup - Flag indicating whether the edit popup is open.
 * @param {function} props.setIsOpenAddPopup - Function to set the state of isOpenAddPopup.
 * @returns {JSX.Element} The FmgDocs component.
 */
const FmgDocs = ({ isOpenAddPopup, setIsOpenAddPopup }) => {
  const dispatch = useDispatch();
  const fmgDocsState = useSelector(state => state.food_safety_quality);
  const [editItem, setEditItem] = useState(null);
  const tenant = getTenantFromDomain();

  const [paginationState, setPaginationState] = useState({
    itemsPerPage: 10,
    currentPage: 1,
    query: '',
  });

  const handleSearch = e => {
    const { value: searchInputValue } = e.target;

    setPaginationState(prevState => ({
      ...prevState,
      currentPage: 1,
      query: searchInputValue,
    }));
  };

  const onEditHandler = _item => {
    setEditItem(_item);
    setIsOpenAddPopup(true);
  };

  const handleItemsPerPageSelect = e => {
    const currentPageValue = 1;
    const itemsPerPageValue = parseInt(e.target.value, 10);

    setPaginationState({
      ...paginationState,
      itemsPerPage: itemsPerPageValue,
      currentPage: currentPageValue,
    });
  };

  const handlePageClick = selected => {
    setPaginationState({ ...paginationState, currentPage: selected });
  };

  const fetchResourcesHandler = useCallback(
    debounce(pState => dispatch(fetchResources('btt', pState)), 300),
    []
  );

  useEffect(() => {
    fetchResourcesHandler(paginationState);
  }, [paginationState.currentPage, paginationState.itemsPerPage, paginationState.query]);

  useEffect(() => {
    if (!isOpenAddPopup) setEditItem(null);
  }, [isOpenAddPopup]);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" width="100%" marginBlock="20px">
        <HStack spacing="10px">
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            BTT Guide / {tenant.includes('cfc') ? 'CFCO' : 'FMG' } Docs
          </Heading>
        </HStack>

        <InputGroup marginTop="5px !important" width="264px">
          <InputLeftElement>
            <SearchIcon color="#0000005E" />
          </InputLeftElement>
          <Input
            marginLeft="auto !important"
            placeholder="Search"
            width="100%"
            height="40px"
            fontWeight="normal !important"
            value={paginationState.query}
            backgroundColor="#ffffff"
            onChange={handleSearch}
          />
        </InputGroup>
      </Flex>
      <VStack
        align="stretch"
        spacing="20px"
        padding="30px"
        boxShadow="md"
        borderRadius="6px"
        backgroundColor="card.default"
        width="100%"
        minHeight="calc(100vh - 320px)"
      >
        {fmgDocsState.loading && (
          <Stack>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
              <Skeleton key={i} height="25px" />
            ))}
          </Stack>
        )}

        {!fmgDocsState.loading && fmgDocsState.btt && fmgDocsState?.btt?.items?.length ? (
          <>
            <Table
              pagination={{
                pageSize: paginationState.itemsPerPage,
                current: paginationState.currentPage,
                style: { display: 'none' },
              }}
              rowClassName="food-safety-table-row"
              className="food-safety-table"
              dataSource={fmgDocsState?.btt?.items?.map(itm => ({
                ...itm,
                actions: (
                  <Flex gap={3} css={{ gap: '10px' }}>
                    <Tooltip content="Edit">
                      <IconButton
                        width="24px"
                        height="24px"
                        padding="0"
                        minW="auto"
                        border="1px solid #D9DADF"
                        borderRadius="2px"
                        color="#00000099"
                        backgroundColor="#fff"
                        icon={<RiPencilFill size={12} />}
                        onClick={() => onEditHandler(itm)}
                      />
                    </Tooltip>
                    { itm?.rs_path.endsWith('doc') || itm?.rs_path.endsWith('docx') ? () => ('') : <ViewDocument url={itm.rs_path} /> }
                    <DownloadResource url={itm.rs_path} />
                  </Flex>
                ),
              }))}
              columns={fmgColumns}
            />
            <Flex marginTop="auto !important">
              <HStack marginLeft="auto" minW="100px">
                <Select
                  width="fit-content"
                  marginLeft="auto"
                  onChange={e => handleItemsPerPageSelect(e)}
                  value={paginationState.itemsPerPage}
                >
                  {[10, 20, 30, 40, 50].map(item => (
                    <option key={item} value={item}>
                      Show {item}
                    </option>
                  ))}
                </Select>
              </HStack>
              <PaginationBar
                currentPage={paginationState.currentPage}
                pages={fmgDocsState?.btt?.pages}
                onPageChange={page => handlePageClick(page)}
                justifyContent="flex-end"
              />
            </Flex>
          </>
        ) : (
          !fmgDocsState.loading && <NoData />
        )}
      </VStack>
      {isOpenAddPopup && (
        <AddDocument
          editItem={editItem}
          resourceType="btt"
          isOpenAddPopup={isOpenAddPopup}
          setIsOpenAddPopup={setIsOpenAddPopup}
        />
      )}
    </>
  );
};

FmgDocs.propTypes = {
  isOpenAddPopup: PropTypes.bool,
  setIsOpenAddPopup: PropTypes.func,
};

export default FmgDocs;
